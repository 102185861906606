const gatewaySettingsConfig = [
  {
    type: "input_number",
    inputType: "number",
    label: "Settings Interval (mins)",
    model: "settings_interval",
    validationRules: "required"
  },
  {
    type: "input_number",
    inputType: "number",
    label: "Device Stats Interval (mins)",
    model: "device_stats_interval",
    validationRules: "required"
  },
  {
    type: "input",
    inputType: "text",
    label: "Lora Channel",
    model: "lora_channel",
    validationRules: "required"
  },
  // {
  //   type: "input",
  //   inputType: "text",
  //   label: "Lora Channel",
  //   model: "lora_channel",
  //   validationRules: "required"
  // },
  {
    type: "input",
    inputType: "text",
    label: "Latitude",
    model: "latitude",
    validationRules: ""
  },
  {
    type: "input",
    inputType: "text",
    label: "Longitude",
    model: "longitude",
    validationRules: ""
  },
  {
    type: "checkbox",
    inputType: "boolean",
    label: "Reboot",
    model: "reboot",
    validationRules: "required"
  },
  {
    type: "checkbox",
    inputType: "boolean",
    label: "Restart Service",
    model: "restart_service",
    validationRules: "required"
  },
  {
    type: "select",
    inputType: "text",
    label: "Country",
    model: "country"
  },
  {
    type: "input",
    inputType: "text",
    label: "MAC Address",
    model: "mac_address",
    validationRules: ""
  },
  {
    type: "input",
    inputType: "text",
    label: "OS Version",
    model: "os_version",
    validationRules: ""
  },
  {
    type: "input",
    inputType: "text",
    label: "Software Version",
    model: "sw_version",
    validationRules: ""
  },
  {
    type: "input",
    inputType: "text",
    label: "Hardware Version",
    model: "hw_version",
    validationRules: ""
  },
  {
    type: "input",
    inputType: "text",
    label: "IP Interface",
    model: "ip_interface",
    validationRules: ""
  },
  {
    type: "input",
    inputType: "text",
    label: "Mac Id",
    model: "mac_id",
    validationRules: ""
  },
  {
    type: "input",
    inputType: "text",
    label: "Gateway IP Address",
    model: "ip_address",
    validationRules: ""
  },
  {
    type: "input",
    inputType: "text",
    label: "Group Settings",
    model: "group_settings",
    validationRules: { validFormat: { format: "JSON" } }
  }
].map(keyConfig => {
  return {
    ...keyConfig,
    property: keyConfig.model,
    getMethodParams: ({ activityLog }) => [
      activityLog,
      `data.${keyConfig.model}`
    ]
  };
});

export default gatewaySettingsConfig;
