<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: indexAuthView.vue
Description:This file gives the information about (Landing Page)login and sign up Screens.
-->
<template>
  <el-container class="containers-holder">
    <div class="image__eruvaka-holder">
      <er-logo />
    </div>
    <language-select class="language-selector-position"></language-select>
    <div class="company-name">
      <p>
        <a href="https://www.eruvaka.com">Eruvaka Technologies</a>
      </p>
    </div>
    <div class="blue-container"></div>
    <router-view class="component-grid-position"></router-view>
  </el-container>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin.js";
import languageSelect from "@/components/auth/languageSelect";
export default {
  mixins: [errorHandlerMixin],
  props: ["componentName"],
  components: { languageSelect },
  data() {
    return {
      auth2: null,
      swapContainer: false
    };
  },
  computed: {
    isSocialSignRequired: function() {
      return this.$route.path === "/sign-in" || this.$route.path === "/sign-up";
    }
  },
  methods: {}
};
</script>

<style lang="scss">
.containers-holder {
  height: 100vh;
  width: 100vw;
  background-image: url("../../assets/home_back.jpg");
  background-size: 100%;
  overflow: auto;
  display: grid;

  @include respond-to(tablet) {
    @include fluid-type(
      $vw_1_width,
      $vw_2_width,
      $vw_3_width,
      $font_size_1,
      $font_size_2
    );
  }
  @include respond-to(medium-screens) {
    @include fluid-type(
      $vw_1_width,
      $vw_2_width,
      $vw_3_width,
      $font_size_2,
      $font_size_3
    );
  }
  @include respond-to(wide-screens) {
    @include fluid-type(
      $vw_1_width,
      $vw_2_width,
      $vw_3_width,
      $font_size_2,
      $font_size_3
    );
  }
  @include respond-to(phone) {
    grid-template-columns: 3em 3em 2em 3em auto 3em 2em 3em 0em;
    grid-template-rows: 1em 3em 1em 5em auto 5em 1em 3em 2em;
  }
  @include respond-to(tablet) {
    grid-template-columns: 3em 3em 2em 3em auto 3em 2em 3em 3em;
    grid-template-rows: 1em 3em 1em 5em auto 5em 1em 3em 2em;
  }
  @include respond-to(medium-screens) {
    grid-template-columns: 3em 3em 2em 3em auto 3em 2em 3em 3em;
    grid-template-rows: 2em 3em 1em 5em auto 5em 1em 3em 2em;
  }
  @include respond-to(wide-screens) {
    grid-template-columns: 3em 3em 2em 3em auto 3em 2em 3em 3em;
    grid-template-rows: 2em 3em 1em 5em auto 5em 1em 3em 2em;
  }
  .el-form-item--mini.el-form-item,
  .el-form-item--small.el-form-item {
    margin-bottom: 25px;
    display: block;
  }

  .container__form-holder {
    position: relative;
    top: 0px;
    bottom: 0px;
    left: 0px;
    width: 50%;
    .main-content {
      margin: auto;
      padding: 0px 200px;
    }
  }
  .image__eruvaka-holder {
    @include respond-to(tablet) {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-end: 3;
      grid-row-start: 2;
    }
    @include respond-to(medium-screens) {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-end: 3;
      grid-row-start: 2;
    }
    @include respond-to(wide-screens) {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-end: 3;
      grid-row-start: 2;
    }

    img {
      width: 3em;
      height: 3em;
      padding: 0.2em;
      margin: auto;
    }
  }
  .home-back-ground-image {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
  }
  // .company-name {
  //   grid-column-start: 3;
  //   grid-column-end: 6;
  //   grid-row-end: 3;
  //   grid-row-start: 2;
  //   line-height: 2.2;
  //   margin-left: 11px;
  //   color: white;
  //   a {
  //     text-decoration: none;
  //     color: white;
  //     @include responsiveProperty(font-size, 1.5em, 1.5em, 1.6em);
  //   }
  // }
  .company-name {
    @include respond-to(tablet) {
      grid-column-start: 3;
      grid-column-end: 6;
      grid-row-end: 3;
      grid-row-start: 2;
      line-height: 2.2;
      margin-left: 11px;
      color: white;
      a {
        text-decoration: none;
        color: white;
        @include responsiveProperty(font-size, 1.5em, 1.5em, 1.6em);
      }
    }
    @include respond-to(medium-screens) {
      grid-column-start: 3;
      grid-column-end: 6;
      grid-row-end: 3;
      grid-row-start: 2;
      line-height: 2.2;
      margin-left: 11px;
      color: white;
      a {
        text-decoration: none;
        color: white;
        @include responsiveProperty(font-size, 1.5em, 1.5em, 1.6em);
      }
    }
    @include respond-to(wide-screens) {
      grid-column-start: 3;
      grid-column-end: 6;
      grid-row-end: 3;
      grid-row-start: 2;
      line-height: 2.2;
      margin-left: 11px;
      color: white;
      a {
        text-decoration: none;
        color: white;
        @include responsiveProperty(font-size, 1.5em, 1.5em, 1.6em);
      }
    }
    @include respond-to(phone) {
      grid-column-start: 2;
      grid-column-end: 6;
      grid-row-start: 1;
      grid-row-end: 5;
      line-height: 2;
      a {
        text-decoration: none;
        color: white;
        @include responsiveProperty(font-size, 1.5em, 1.5em, 1.6em);
      }
    }
  }
  @include respond-to(phone) {
    .blue-container {
      display: none;
    }
  }
  .blue-container {
    grid-column-start: 4;
    grid-column-end: 7;
    grid-row-start: 5;
    grid-row-end: 6;
    background-color: #2b3d4fe8;
    border-radius: 10px;
  }
  .component-grid-position {
    grid-column-start: 5;
    grid-column-end: 5;
    grid-row-start: 4;
    grid-row-end: 7;
  }
  .language-selector-position {
    grid-column-start: 6;
    grid-column-end: 9;
    grid-row-start: 2;
    grid-row-end: 2;
    line-height: 3;
  }
  .btn_submit {
    @include button-text-size;
    background-color: #389918;
    color: white;
    // margin-bottom: 10px;
    min-width: 10.5rem;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }
  .btn_back_to_login {
    font-size: 14px;
    // background-color: #fffff;
    border-radius: 10px;
    margin-right: 10px;
  }
  .el-input-group__append,
  .el-input-group__prepend {
    color: #1e7bd7;
  }
}
</style>
