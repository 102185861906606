/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: user.js
Description: Contains all vuex store functions of user
*/
import User from "../../model/user";
import { $axios } from "../../config/axios";
import { cloneDeep } from "lodash";
import userService from "@/services/userService";
import thirdPartyService from "@/services/ThirdPartyService";
import { saveUserDetailsToLocalStorage } from "@/middleware/auth";
import featureToggleManager from "@/middleware/featureToggleManager";
import mixpanel from "mixpanel-browser";
import {
  groupPermissions,
  FTPermissions,
  FTSchEditPermissions,
  userTypes as storeUserTypes
} from "@/middleware/pageAccessManager";
// import countryToDefaultUnits from "@/constants/units";
const defaultPreferredUnits = {
  allowed_device_alerts: [],
  abw_and_growth_units: "g",
  biomass_per_area_units: { biomass_units: "lb", pond_area_units: "ha" },
  biomass_units: "lb",
  date_format: "dd MMM, yy",
  device_name: "alias_name",
  do_units: "mg/L",
  feed_units: "kg",
  language: "es",
  pond_area_units: "ha",
  temperature_units: "c",
  time_format: "HH:mm",
  feed_gap: "HIDE",
  default_dashboard_tab: "dashboardTab"
};
const state = {
  user: new User(),
  currUserLocation: undefined,
  userLocations: [],
  errorAlerts: [],
  timezones: [],
  selectTimeZone: {},
  filters: {
    page: 1,
    limit: 30
  },
  isAquasimAvailable: false,
  preferredUnits: cloneDeep(defaultPreferredUnits), // countryToDefaultUnits.other
  // preferredUnits: countryToDefaultUnits.other,
  userDeviceData: {},
  userDevicesCountData: {}
};
const getters = {
  getUserProfile: state => {
    return state.user;
  },
  getPreferredUnits: state => {
    return state.preferredUnits;
  },
  getDeviceTypesEnabledForUser(state, getters) {
    const deviceTypesToUsers = {
      POND_GUARD: featureToggleManager.ALL_USERS,
      POND_MOTHER: featureToggleManager.ALL_USERS,
      SHRIMP_TALK: featureToggleManager.ALL_USERS,
      GATEWAY: featureToggleManager.ALL_USERS,
      FEED_BLOWER: [
        "5e1897b5188b23258b34654d",
        "5e565a0cbc2c4dd8b5f6f041",
        "5f1926e917a2492077983958",
        "5e5cfb322c599d13d25b607e",
        "5e1897b5188b23258b346396"
      ],
      SHRIMP_SNAP: [
        "5e1897b5188b23258b34654d",
        "5e565a0cbc2c4dd8b5f6f041",
        "5f1926e917a2492077983958",
        "5e5cfb322c599d13d25b607e",
        "5e1897b5188b23258b346396",
        "5e1897b5188b23258b3465c3",
        "5e1897b5188b23258b34637e",
        "5e1897b5188b23258b34638e",
        "5e565a0cbc2c4dd8b5f6f04c",
        "5ff6cf2016578b2c1c93c940",
        "61331ae3bc214f7f70e834ad"
      ]
    };
    const currUser = getters.getUserProfile;
    return Object.keys(deviceTypesToUsers).filter(device_type => {
      return featureToggleManager.isFeatureAvailable(
        deviceTypesToUsers[device_type],
        currUser
      );
    });
  },
  getUserId: (state, getters) => {
    return getters.getUserProfile._id;
  },
  getCreatedBy: (state, getters) => {
    return getters.getUserProfile.created_by;
  },
  isReadOnly: (state, getters) => {
    if (!state.user) {
      return false;
    }
    const { v2_access, created_by } = getters.getUserProfile;
    return (
      (process.env.VUE_APP_STAGE !== "DEV" &&
        v2_access !== undefined &&
        v2_access === "READ_ONLY") ||
      (process.env.VUE_APP_STAGE === "STAGING" &&
        ![
          "5f47724756751a1b6d427dd2",
          "5f098445be50fddb616e47c9",
          "6030df0c46d1454004c9241c",
          "5e1897b5188b23258b346396",
          "5e1897b5188b23258b346395"
        ].includes(created_by || getters.getUserId))
    );
  },
  getPermissions: (state, getters) => {
    return groupPermissions(getters.getUserProfile.permissions);
  },
  getBkPermissions: (state, getters) => {
    return getters.getUserProfile.permissions;
  },
  canUserAccessApiByBkPermissions: (state, getters) => {
    const userType = getters.getCurrUserType;
    const isPondOwnerOrManager = [
      storeUserTypes.admin,
      storeUserTypes.pond_owner,
      storeUserTypes.pond_manager
    ].includes(userType);
    const bkPermissions = getters.getBkPermissions;
    if (isPondOwnerOrManager) {
      return () => true;
    } else {
      return requirePermissions => {
        return requirePermissions.some(permission =>
          bkPermissions.includes(permission)
        );
      };
    }
  },
  doRestrictHighPriorityUsers: (state, getters) => {
    if (!state.user) {
      return false;
    }
    const { v2_access } = getters.getUserProfile;
    return v2_access !== undefined && v2_access === "PARTIAL_EDIT";
  },
  getViewPermissions: (state, getters) => {
    const userType = getters.getCurrUserType;
    const isPondOwnerOrManager = [
      storeUserTypes.admin,
      storeUserTypes.pond_owner,
      storeUserTypes.pond_manager
    ].includes(userType);
    return FTPermissions.reduce((acc, curr) => {
      acc[curr] = isPondOwnerOrManager || getters.getPermissions[curr].VIEW;
      return acc;
    }, {});
  },
  getUpdatePermissions: (state, getters) => {
    const userType = getters.getCurrUserType;
    const isPondOwnerOrManager = [
      storeUserTypes.admin,
      storeUserTypes.pond_owner,
      storeUserTypes.pond_manager
    ].includes(userType);
    // only true settings and schedules
    if (getters.isReadOnly) {
      return FTSchEditPermissions.reduce((acc, curr) => {
        acc[curr] = false;
        return acc;
      }, {});
    }
    if (getters.doRestrictHighPriorityUsers) {
      return FTSchEditPermissions.reduce((acc, curr) => {
        acc[curr] = curr === "MANAGE_EDIT_SCHEDULES";
        return acc;
      }, {});
    } else {
      return FTPermissions.reduce((acc, curr) => {
        acc[curr] = isPondOwnerOrManager || getters.getPermissions[curr].UPDATE;
        return acc;
      }, {});
    }
  },
  getDeletePermissions: (state, getters) => {
    const userType = getters.getCurrUserType;
    const isPondOwnerOrManager = [
      storeUserTypes.admin,
      storeUserTypes.pond_owner,
      storeUserTypes.pond_manager
    ].includes(userType);
    if (getters.isReadOnly) {
      return FTSchEditPermissions.reduce((acc, curr) => {
        acc[curr] = false;
        return acc;
      }, {});
    }
    if (getters.doRestrictHighPriorityUsers) {
      return FTSchEditPermissions.reduce((acc, curr) => {
        acc[curr] = curr === "MANAGE_EDIT_SCHEDULES";
        return acc;
      }, {});
    }
    return FTPermissions.reduce((acc, curr) => {
      acc[curr] = isPondOwnerOrManager || getters.getPermissions[curr].DELETE;
      return acc;
    }, {});
  },
  getUserTimeZoneString(state, getters) {
    return getters.getUserTimeZone.name;
  },
  getProfileUrl: state => {
    return state.user.company_logo
      ? `${state.user.company_logo}?${state.user.updated_at}`
      : undefined;
  },
  getCurrUserType: state => {
    return state.user.user_type;
  },
  getUserCountryName: state => {
    return state.user.country.name;
  },
  getUserCountryCode: state => {
    return state.user.country.code;
  },
  getUserTimeZone: (state, getters) => {
    return getters.getUserProfile.timezone;
  },
  getUserTimeZoneOffset: (state, getters) => {
    return getters.getUserProfile.timezone.offset;
  },
  getUserLocationsObj: state => {
    const locations = {};
    cloneDeep(state.userLocations)
      .sort((a, b) => {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      })
      .forEach(location => {
        if (location.status === "ACTIVE" || location.status === undefined) {
          locations[location._id] = location;
        }
      });
    return locations;
  },
  getUserLocations: state => {
    return state.userLocations.filter(x => x.status === "ACTIVE");
  },
  getCurrUserLocation: (state, getters) => {
    if (state.currUserLocation) {
      return state.currUserLocation;
    } else if (getters.getUserLocations.length > 0) {
      return getters.getUserLocations[0];
    } else {
      return {};
    }
  },
  getTimeZones(state) {
    const timezonesObj = {};
    state.timezones.forEach(timezone => {
      timezonesObj[timezone.name] = timezone;
    });
    return timezonesObj;
  },
  isAquasimAvailable: state => {
    return state.isAquasimAvailable;
  },
  getUserDeviceData: state => {
    return state.userDeviceData;
  },
  getUserDevicesCountData: state => {
    return state.userDevicesCountData;
  }
};
const mutations = {
  SET_USER_DATA(state, userData) {
    if (userData) {
      var user = new User();
      user.castToUserObj(userData);
      const userType = userData.user_type;
      if (
        userType === storeUserTypes.admin ||
        userType === storeUserTypes.pond_owner
      ) {
        user.is_admin = true;
      } else if (userType === storeUserTypes.super_admin) {
        user.permissions = [storeUserTypes.super_admin];
      }
      saveUserDetailsToLocalStorage(user);
      state.user = user;
    } else {
      state.user = new User();
    }
  },
  SET_CURR_DEVICE_LOCATION(state, location) {
    state.currUserLocation = location;
  },
  SET_USER_LOCATIONS(state, locations) {
    state.userLocations = locations;
  },
  SET_ERROR_ALERTS(state, errorAlerts) {
    state.errorAlerts = errorAlerts;
  },
  SET_TIMEZONES_LIST(state, timezones) {
    state.timezones = timezones;
  },
  SET_AQUASIM_AVAILABILITY(state, isAvailable) {
    state.isAquasimAvailable = isAvailable;
  },
  SET_PREFERRED_UNITS(state, units) {
    // let defaultUnits = countryToDefaultUnits.india;
    // if (state.user.country.name.toLowerCase() !== "india" || state.user.country.name.toLowerCase() !== "sri lanka") {
    //   defaultUnits = countryToDefaultUnits.other;
    // }
    const {
      allowed_device_alerts,
      abw_and_growth_units,
      biomass_per_area_units,
      biomass_units,
      date_format,
      do_units,
      feed_units,
      language,
      pond_area_units,
      temperature_units,
      time_format,
      device_name,
      feed_gap,
      default_location_id,
      default_dashboard_tab
    } = { ...units };

    state.preferredUnits = {
      abw_and_growth_units: abw_and_growth_units.toLowerCase(),
      allowed_device_alerts: allowed_device_alerts || [],
      biomass_per_area_units,
      biomass_units: biomass_units.toLowerCase(),
      date_format,
      do_units: do_units.toLowerCase(),
      feed_units: feed_units.toLowerCase(),
      language,
      pond_area_units: pond_area_units.toLowerCase(),
      temperature_units: temperature_units.toLowerCase(),
      time_format,
      device_name: device_name.toLowerCase(),
      feed_gap: feed_gap || "HIDE",
      default_dashboard_tab: default_dashboard_tab || "dashboardTab",
      default_location_id: default_location_id
    };
  },
  SET_USER_DEVICE_DATA(state, deviceData) {
    state.userDeviceData = deviceData;
  },
  SET_USER_DEVICES_COUNT_DATA(state, devicecountData) {
    state.userDevicesCountData = devicecountData;
  }
};
const actions = {
  /**
   * Actions for user
   */
  fetchProfilePicS3SaveUrl: async function() {
    const response = await userService.getProfilePictureAWSS3URL();
    return response.data.data;
  },
  notifyBackendOnLogout: async function() {
    await userService.notifyBackendOnLogoutService();
  },
  saveProfilePictureToTargetUrl: async function(context, file) {
    const { target_url: targetUrl, file_id } = await context.dispatch(
      "fetchProfilePicS3SaveUrl"
    );
    await userService.saveProfilePictureToTargetUrl(targetUrl, file);
    await userService.informUploadStatusToBackend(file_id);
  },
  fetchUserProfile: context => {
    return new Promise((resolve, reject) => {
      $axios
        .get("/profile")
        .then(async response => {
          context.commit("SET_USER_DATA", response.data.user_details);
          context.commit("SET_PREFERRED_UNITS", {
            ...cloneDeep(defaultPreferredUnits),
            ...response.data.user_details.global_settings
          });
          context.commit(
            "SET_USER_DEVICE_DATA",
            response.data.user_devices_count
          );

          await context.dispatch("fetchThirdParties");
          const userDetails = response.data.user_details;

          resolve(userDetails);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updatePassword: (context, updatePasswordDetails) => {
    return new Promise((resolve, reject) => {
      $axios
        .post("/password/update", updatePasswordDetails)
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateUserProfile: (context, user) => {
    return new Promise((resolve, reject) => {
      $axios
        .post("/profile", user)
        .then(response1 => {
          context
            .dispatch("fetchUserProfile")
            .then(response2 => {
              resolve(response1.data);
            })
            .catch(err => {
              reject(err);
            });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchTimeZones: (context, country_code) => {
    return new Promise((resolve, reject) => {
      $axios
        .get(`/countries/${country_code}/timezones`)
        .then(async response => {
          context.commit("SET_TIMEZONES_LIST", response.data.timezones);
          resolve("success");
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  /**
   * Actions for locations
   */
  fetchUserLocations: async function(context) {
    const response = await $axios.get("/users/locations");
    const userLocations = response.data.user_locations;
    context.commit("SET_USER_LOCATIONS", userLocations);
  },
  setCurrUserLocation: async function(context, location_id) {
    const locations = context.getters.getUserLocationsObj;
    const userLocations = context.getters.getUserLocations;
    const preferredLocation = context.getters.getPreferredUnits;
    const noOflocations = Object.keys(userLocations).length;
    const hasLocations = noOflocations > 0;
    if (!hasLocations) return undefined;
    const current_location =
      locations[location_id] ||
      locations[preferredLocation.default_location_id] ||
      userLocations[0];
    context.commit("SET_CURR_DEVICE_LOCATION", current_location);
  },
  createLocation: function(context, location) {
    return new Promise((resolve, reject) => {
      $axios
        .post("/users/locations", location)
        .then(response1 => {
          resolve(response1.data.location);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateCurrUserLocation: (context, location) => {
    context.commit("SET_CURR_DEVICE_LOCATION", location);
  },
  updateLocationName: async (context, { locationId, location_name }) => {
    await $axios.patch(`/users/locations/${locationId}`, { location_name });
    await context.dispatch("fetchUserLocations");
    if (locationId === context.getters.getCurrUserLocation._id) {
      context.dispatch("setCurrUserLocation", locationId);
    }
  },
  deleteLocation: async (context, locationId) => {
    await $axios.delete(`/users/locations/${locationId}`);
  },

  /**
   *  Actions for Error Alerts
   */
  fetchPMErrorHistory: (context, { pondmothercode, params }) => {
    return new Promise((resolve, reject) => {
      $axios
        .get(`/ponds/pond-mothers/${pondmothercode}/sensor-data-alerts/`, {
          params
        })
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  /**
   *Subuser Actions
   */

  async fetchThirdParties(context) {
    const res = await thirdPartyService.getThirdParties();
    const thirdParties = res.data.third_parties;
    const aquasimData = thirdParties.find(
      service => service.title === "aquasim"
    );
    if (aquasimData) {
      context.commit("SET_AQUASIM_AVAILABILITY", true);
    } else {
      context.commit("SET_AQUASIM_AVAILABILITY", false);
    }
  },
  mixPanelEventGenerator(context, { eventName }) {
    const isImpressionLocal =
      JSON.parse(localStorage.getItem("is-impersonation")) || false;
    const impressionByLocal =
      JSON.parse(localStorage.getItem("is-impersonation-by")) || "";
    const userTypeLocal =
      JSON.parse(localStorage.getItem("curr-user-type")) || "";
    // setTimeout(
    //   () =>
    console.log("eventName", eventName);
    mixpanel.track(eventName, {
      user_type: isImpressionLocal ? impressionByLocal : userTypeLocal,
      tabName: new URLSearchParams(window.location.search).get("tab") || "",
      PageEvents: eventName,
      title: document.title || "",
      isImpersonation: isImpressionLocal,
      impersonationBy: impressionByLocal
    });
  },
  fetchUserDevicesCountData: async function(context, payload) {
    const response = await $axios.get(
      `/user-devices?user_id=${payload.user_id}`
    );
    context.commit("SET_USER_DEVICES_COUNT_DATA", response.data.data);
  },
  sendOTP: async function(context, payload) {
    const response = await $axios.post("/send-otp", payload);
    return response.data;
  },
  verifyOTP: async function(context, payload) {
    const response = await $axios.post("/verify-otp", payload);
    return response.data;
  }
};

export default {
  namespaced: "true",
  state,
  getters,
  mutations,
  actions
};
