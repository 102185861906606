/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: errorKeyMapMixin.js
Description: This mixin is useful for handling the validations messages return from the backend for component with forms
*/
const errorKeyMapMixin = {
  computed: {
    ekmm__castErrorKeyToLang() {
      return ({ key, error_code }) => {
        if (!key || !error_code) return;
        return this.ekmm__errorLangKeyMapper(key, error_code);
      };
    },
    superUser__castErrorKeyToLang() {
      return ({ key, error_code }) => {
        if (!key || !error_code) return;
        return this.superUser__errorLangKeyMapper(key, error_code);
      };
    }
  },
  data: function() {
    return {
      ekmm__backndfieldToFieldNameMap: {
        first_name: "Usrs_first_name",
        last_name: "Usrs_last_name",
        email: "Comn_email",
        password: "Usrs_pwd",
        confirm_password: "Comn_confirm_pwd",
        name: "Comn_country",
        phone: "Comn_mobile_num",
        mobile: "Comn_mobile_num",
        type: "Comn_device_type",
        abw: "ABW_abw",
        biomass: "Comn_biomass",
        cumulative_feed: "Comn_cumu_feed",
        survival_percentage: "Comn_survival_percentage",
        date: "Comn_date",
        fcr: "Comn_fcr",
        pond_id: "Comn_pond_name",
        user_type: "Comn_user_type",
        current_password: "Usrs_old_password"
      },
      ekmm__backndfieldToInvalidMap: {
        first_name: { INVALID_VALUE: "Usrs_first_name_wrng_format" },
        last_name: { INVALID_VALUE: "Usrs_first_name_wrng_format" },
        email: { INVALID_VALUE: "email_in_valid_format" },
        password: { INVALID_VALUE: "Usrs_pwd_wrng_frmt" },
        confirm_password: { INVALID_VALUE: "Usrs_pwd_cnpwd_valida" },
        name: { INVALID_VALUE: "Comn_country" },
        phone: { INVALID_VALUE: "Comn_mobile_num_valid" },
        mobile: { INVALID_VALUE: "Comn_mobile_num_valid" },
        type: { INVALID_VALUE: "Comn_device_type" },
        abw: { INVALID_VALUE: "ABW_require" },
        biomass: { INVALID_VALUE: "Comn_biomass_require" },
        cumulative_feed: { FIELD_REQUIRED: "Comn_cumu_feed" },
        survival_percentage: {
          FIELD_REQUIRED: "Comn_survival_percentage",
          INVALID_VALUE: "Comn_survival_percentage_invalid"
        },
        date: { FIELD_REQUIRED: "Comn_date" },
        fcr: { FIELD_REQUIRED: "Comn_fcr" },
        pond_id: { FIELD_REQUIRED: "Comn_pond_name" }
      },
      superUser__backndfieldToInvalidMap: {
        first_name: {
          INVALID_VALUE: "Only Letters and Space are allowed",
          FIELD_REQUIRED: " First Name is required"
        },
        last_name: {
          INVALID_VALUE: "Only Letters and Space are allowed",
          FIELD_REQUIRED: " Last Name is required"
        },
        email: {
          INVALID_VALUE: "Email must be in valid format",
          FIELD_REQUIRED: " Email is required"
        },
        password: {
          INVALID_VALUE: "Password must satisfy the below guidelines",
          FIELD_REQUIRED: " Password is required"
        },
        confirm_password: {
          INVALID_VALUE: "Password must satisfy the below guidelines",
          FIELD_REQUIRED: " Confirm Password is required"
        },
        name: {
          INVALID_VALUE: "Country is Invalid",
          FIELD_REQUIRED: " Country is required"
        },
        phone: {
          INVALID_VALUE: "Mobile number is Invalid",
          FIELD_REQUIRED: " Mobile Number is required"
        },
        timezone: {
          INVALID_VALUE: "Timezone is required",
          FIELD_REQUIRED: " Timezone is required"
        }
      }
    };
  },
  methods: {
    ekmm__errorLangKeyMapper: function(key, error_code) {
      const fieldTranslation = this.$tc(
        this.ekmm__backndfieldToFieldNameMap[key],
        1
      );

      switch (error_code) {
        case "FIELD_REQUIRED":
          return this.$t("Comn_field_is_required", { field: fieldTranslation });
        case "INVALID_VALUE":
          return this.$t(this.ekmm__backndfieldToInvalidMap[key].INVALID_VALUE);
        case "VALUE_SHOULD_BE_A_SAFE_NUMBER":
          return this.$tc("Comn_must_greater_than", 1, {
            field: fieldTranslation,
            threshold: this.ekmm__backndfieldToInvalidMap[key]
              .VALUE_SHOULD_BE_A_SAFE_NUMBER
          });
        case "PASSWORD_CONFIRM_PASSWORD_NOT_SAME":
          return this.$t("Password_confirm_password_not_match", {
            field: fieldTranslation
          });
        case "INVALID_PASSWORD":
          return this.$t("Password_must_contain_at_least_6_Characters", {
            field: fieldTranslation
          });
        case "WRONG_CURRENT_PASSWORD":
          return this.$t("Wrong_current_password", {
            field: fieldTranslation
          });
        default:
          return this.$t(this.ekmm__backndfieldToInvalidMap[key][error_code]);
      }
    },
    superUser__errorLangKeyMapper: function(key, error_code) {
      switch (error_code) {
        case "FIELD_REQUIRED":
          return this.superUser__backndfieldToInvalidMap[key].FIELD_REQUIRED;
        case "INVALID_VALUE":
          return this.superUser__backndfieldToInvalidMap[key].INVALID_VALUE;
        case "VALUES_SHOULD_MATCH":
          return "Password & Confirm Password Do Not Match";
        case "VALUE_DO_NOT_HAVE_MIN_CHAR":
          return "Password must satisfy the below guidelines";
        case "WRONG_CURRENT_PASSWORD":
          return "Wrong current password";
        case "EMAIL_ALREADY_EXISTS":
          return "Email is already registered";
        default:
          return this.superUser__backndfieldToInvalidMap[key][error_code];
      }
    }
  }
};
export default errorKeyMapMixin;
