<template>
    <er-dialog
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      @beforeClose="handleCloseDialog"
      @open="handleOpenDialog"
      :title="`${$t('Comn_update')} ${$t('Comn_gateway')}`"
      class="gateway_action_dialog_container"
      custom-class="gateway_action_dialog"
    >
      <ValidationObserver ref="dialogListForm">
        <el-form
          size="small"
          v-loading="loading"
          :model="gatewayObj"
          ref="gatewayObj"
          class="demo-gatewayObj"
        >
          <ValidationProvider
            rules="required"
            :name="$tc('Comn_gateway_id', 0)"
            v-slot="{ errors }"
          >
            <el-form-item
              :label="$tc('Comn_gateway_id', 0)"
              prop="gateway_id"
              :error="errors[0]"
            >
              <er-select
                v-model="gatewayObj.gateway_id"
                @change="handleChangeGatewayID"
                :placeholder="$tc('Comn_gateway_id', 0)"
                collapse-tags
                value-key="title"
                :showSelectAll="true"
                :reserve-keyword="true"
                multiple
                filterable
                size="small"
              >
                <template v-for="gateway in getGatewayDataContainer">
                  <el-option
                    v-if="gateway.code"
                    :key="gateway.code"
                    :label="gateway.code"
                    :value="gateway.code"
                  ></el-option>
                </template>
              </er-select>
            </el-form-item>
          </ValidationProvider>

          <ValidationProvider
            rules="required"
            :name="$t('Comn_location_label')"
            v-slot="{ errors }"
          >
            <el-form-item
              :label="$t('Comn_location_label')"
              prop="location_id"
              :error="errors[0]"
            >
              <er-select
                v-model="gatewayObj.location_id"
                @change="handleChangeLocationID"
                :disabled="disable_field"
                :placeholder="$t('Comn_location_label')"
                collapse-tags
                value-key="title"
                :showSelectAll="true"
                :reserve-keyword="true"
                multiple
                filterable
                size="small"
              >
                <template v-for="location in getLocationOfUser">
                  <el-option
                    v-if="location._id"
                    :key="location._id"
                    :label="location.name"
                    :value="location._id"
                  ></el-option>
                </template>
              </er-select>
            </el-form-item>
          </ValidationProvider>

          <ValidationProvider
            v-if="showAllowOtherLocationDisplay"
            :name="$t('allow_other_location_device')"
            immediate
            v-slot="{ errors }"
          >
            <el-form-item
              :label="$t('allow_other_location_device')"
              prop="allow_other_location_device"
              :error="errors[0]"
              class="allow-other-location"
            >
              <el-checkbox
                v-model="gatewayObj.allow_other_location_device"
                @change="handleAllowOtherLocationDevice"
                size="small"
                :disabled="disable_field || shouldAllowOtherLocationDisable"
              >
              </el-checkbox>
            </el-form-item>
          </ValidationProvider>
          <ValidationProvider
            :name="$t('manual_wifi_name_label')"
            v-slot="{ errors }"
          >
            <el-form-item
              :label="$t('manual_wifi_name_label')"
              prop="alias_name"
              :error="errors[0]"
            >
              <span slot="label" v-html="$t('manual_wifi_name_label')"></span>
              <!-- v-model="" :precision="0"-->
              <el-input
                v-model="gatewayObj.manual_wifi_name"
                size="small"
                :controls="false"
                :disabled="disable_field"
                @keypress.native="checkWifiName($event)"
                @change="handleWifiName"
                :placeholder="$t('manual_wifi_name_label')"
              ></el-input>
            </el-form-item>
          </ValidationProvider>
          <ValidationProvider
            :name="$t('manual_wifi_password_label')"
            v-slot="{ errors }"
          >
            <el-form-item
              :label="$t('manual_wifi_password_label')"
              prop="alias_name"
              :error="errors[0]"
            >
              <span slot="label" v-html="$t('manual_wifi_password_label')"></span>
              <!-- v-model="" :precision="0"-->
              <el-input
                v-model="gatewayObj.manual_wifi_password"
                size="small"
                :controls="false"
                :disabled="disable_field"
                @change="handleWifiPassword"
                :placeholder="$t('manual_wifi_password_label')"
              ></el-input>
            </el-form-item>
          </ValidationProvider>

          <div class="errorwithouthighlight">
            <span class="material-icons-outlined"> info </span>
            <span>{{ $t('Comn_location_required') }}</span>
          </div>
        </el-form>
      </ValidationObserver>
      <layout-toolbar slot="footer" justify="center">
        <er-button
          size="mini"
          btnType="save"
          :showLabel="true"
          :showIcon="true"
          @click="submitGatewayDetails"
          :loading="loading"
          :disabled="isReadOnly || disable_field"
        ></er-button>
        <er-button
          size="mini"
          btnType="cancel"
          :showLabel="true"
          @click="handleCloseDialog"
          :disabled="isReadOnly"
        ></er-button>
      </layout-toolbar>
    </er-dialog>
  </template>

  <script>
  import errorHandlerMixin from '@/mixins/errorHandlerMixin';
  import errorKeyMapMixin from '@/mixins/errorKeyMapMixin';
  import { mapGetters, mapActions } from 'vuex';
  import { extend } from 'vee-validate';
  import userPreferenceMixin from '@/mixins/userPreferenceMixin';
  import { pondMotherSettings as cnstsPMSettings } from '@/constants/settings';
  import filtersMixin from '@/mixins/filtersMixin.js';
  import {
    required_if,
    required,
    max_value,
    min_value
  } from 'vee-validate/dist/rules';
  // extend("required", {
  //   ...required,
  //   message: "{_field_} is required",
  // });

  export default {
    mixins: [
      errorHandlerMixin,
      errorKeyMapMixin,
      userPreferenceMixin,
      filtersMixin
    ],
    props: {
      showDialog: {
        default: false
      },
      actionToPerform: {
        default: ''
      }
    },
    data: function() {
      return {
        disable_field: false,
        loading: false,
        errorhighlight: false,
        checkmode: false,
        feedermode: false,
        checkdispensefeed: false,
        cnstsPMSettings,
        gatewayObj: {
          gateway_id: [],
          alias_name: '',
          location_id: [],
          allow_other_location_device: '',
          manual_wifi_name: '',
          manual_wifi_password: ''
        },
        gatewayData: [],
        showAllowOtherLocationDisplay: false
      };
    },
    computed: {
      ...mapGetters('user', {
        isReadOnly: 'isReadOnly',
        getPreferredUnits: 'getPreferredUnits',
        getUserLocations: 'getUserLocations',
        getUserProfile: 'getUserProfile'
      }),
      ...mapGetters('gateway', {
        getGatewayData: 'getGatewayData'
      }),
      getLocationOfUser() {
        return this.getUserLocations.map((item) => {
          return {
            ...item,
            name: this.ftm__capitalize(item.name)
          };
        });
      },
      shouldAllowOtherLocationDisable() {
        if (this.gatewayObj.location_id.length > 0) {
          return false;
        }
        return true;
      },
      getGatewayDataContainer() {
        return this.$lodash.cloneDeep(this.getGatewayData).map((x) => {
          x.title = x.title || x.code;
          return x;
        });
      },

      dialogVisible: {
        get() {
          return this.showDialog;
        },
        set(value) {
          this.handleCloseDialog('close');
        }
      }
    },
    created() {
      const features = this.getUserProfile.features;
      if (Object.keys(features).length > 0) {
        const { gateway_location_authentication } = features;
        this.showAllowOtherLocationDisplay = gateway_location_authentication;
      }
    },
    methods: {
      ...mapActions('gateway', {
        fetchGatewayData: 'fetchGatewayData',
        saveGatewayDetails: 'saveGatewayDetails'
      }),
      isNumber(val) {
        if (val.key === ' ' || val.key === '.' || isNaN(val.key)) {
          return val.preventDefault();
        }
      },
      checkWifiName(e) {
        const char = String.fromCharCode(e.keyCode);
        if (/^[A-Za-z0-9]+$/.test(char)) return true;
        else e.preventDefault();
      },
      handleAliasName(updtPropVal) {
        if (updtPropVal !== '') {
          this.checkdispensefeed = true;
          this.errorhighlight = false;
        } else {
          this.checkdispensefeed = false;
          if (this.checkmode && this.feedermode) {
            this.errorhighlight = false;
          } else {
            this.errorhighlight = true;
          }
        }
      },
      handleWifiName(updtPropVal) {
        if (updtPropVal !== '') {
          this.checkdispensefeed = true;
          this.errorhighlight = false;
        } else {
          this.checkdispensefeed = false;
          if (this.checkmode && this.feedermode) {
            this.errorhighlight = false;
          } else {
            this.errorhighlight = true;
          }
        }
      },
      handleWifiPassword(updtPropVal) {
        if (updtPropVal !== '') {
          this.checkdispensefeed = true;
          this.errorhighlight = false;
        } else {
          this.checkdispensefeed = false;
          if (this.checkmode && this.feedermode) {
            this.errorhighlight = false;
          } else {
            this.errorhighlight = true;
          }
        }
      },
      handleOpenDialog() {
        this.initValidations();
        this.disable_field = true;
        this.checkmode = false;
        this.feedermode = false;
        this.checkdispensefeed = false;
        this.errorhighlight = false;
        this.gatewayObj = {
          gayeway_id: [],
          alias_name: '',
          location_id: [],
          allow_other_location_device: '',
          manual_wifi_name: '',
          manual_wifi_password: ''
        };
        this.$nextTick(() => {
          this.$refs.dialogListForm.reset();
        });
      },
      handleChangeGatewayID(gatewayIdArr) {
        if (gatewayIdArr.length > 0) {
          this.disable_field = false;
        } else {
          // this.handleCheckAllPMs(false);
        }
      },
      handleChangeLocationID(gatewayLocationIdArr) {
        if (gatewayLocationIdArr.length > 0) {
          // this.handleCheckAllPMs(true);
          this.disable_field = false;
        } else {
          // this.handleCheckAllPMs(false);
        }
      },
      handleAllowOtherLocationDevice(pondIdArr) {
        if (pondIdArr.length > 0) {
          this.handleCheckAllPMs(true);
          this.disable_field = false;
        } else {
          this.handleCheckAllPMs(false);
        }
      },

      handleCloseDialog(event) {
        this.gatewayObj = {
          gayeway_id: [],
          alias_name: '',
          location_id: [],
          allow_other_location_device: '',
          manual_wifi_name: '',
          manual_wifi_password: ''
        };
        this.$refs.dialogListForm.reset();
        this.$emit('close_dialog', event);
      },
      initValidations() {
        console.log('cnstsPMSettings', cnstsPMSettings);
        extend('required_if_not', {
          ...required_if,
          params: ['target', 'values'],

          validate: (value, args) => {
            console.log(required_if);
            const target = args.target;
            const values = args.values;
            if (value) {
              return Boolean(value);
            } else {
              if (
                values.feeder_type !== '' ||
                values.kg_dispense_time_sec !== undefined ||
                values.mode !== ''
              ) {
                return true;
              }
              target.length > 0 &&
                target.map((item) => {
                  if (values.item) {
                    return Boolean(item);
                  } else {
                    return false;
                  }
                });
            }
            return false;
          },
          message: ' '
        });

        extend('required', {
          ...required,
          message: this.$t('Comn_field_is_required', {
            field: '{_field_}'
          })
        });
        extend('max_value', {
          ...max_value,
          message: this.$tc('Comn_must_less_than_or_equal', 0, {
            field: '{_field_}',
            other_field: this.cnstsPMSettings.kg_dispense_time_secs[1]
          })
        });
        extend('min_value', {
          ...min_value,
          message: this.$tc('Comn_must_greater_than_or_equal', 0, {
            field: '{_field_}',
            other_field: this.cnstsPMSettings.kg_dispense_time_secs[0]
          })
        });
      },

      async submitGatewayDetails() {
        const response = await this.$refs.dialogListForm.validate();

        if (!response) {
          if (this.gatewayObj.location_id.length > 0) {
            this.errorhighlight = false;
            return '';
          } else {
            this.errorhighlight = true;
            return '';
          }
        }
        const formattedGatewayPayload = this.gatewayObj.gateway_id.reduce(
          (acc, currentObj) => {
            const getMainGatewayObj = this.getGatewayDataContainer.filter(
              (item) => item.code === currentObj
            )[0];
            const {
              location_id,
              allow_other_location_device,
              manual_wifi_name,
              manual_wifi_password
            } = this.gatewayObj;
            // if (!acc[currentObj]) {
            //   return;
            // }
            acc[currentObj] =
              this.showAllowOtherLocationDisplay === true
                ? {
                    gateway_id: getMainGatewayObj._id,
                    location_ids: location_id,
                    title: getMainGatewayObj.title,
                    allow_all_devices: allow_other_location_device,
                    manual_wifi_name: manual_wifi_name,
                    manual_wifi_password: manual_wifi_password
                  }
                : {
                    gateway_id: getMainGatewayObj._id,
                    location_ids: location_id,
                    title: getMainGatewayObj.title,
                    manual_wifi_name: manual_wifi_name,
                    manual_wifi_password: manual_wifi_password
                  };
            return acc;
          },
          {}
        );

        this.loading = true;
        console.log('formattedGatewayPayload', formattedGatewayPayload);
        try {
          Object.values(formattedGatewayPayload).forEach((item, i) => {
            Object.keys(item).forEach((key) => {
              if (item[key] === '') {
                delete item[key];
              }
            });
          });
          await this.saveGatewayDetails(Object.values(formattedGatewayPayload));
          this.$notify({
            title: this.$t('Usrs_success_msg'),
            message: this.$t('GW_upd_success'),
            duration: 5000,
            type: 'success'
          });
        } catch (err) {
          this.ehm__errorMessages(err, true);
        } finally {
          this.$emit('close_dialog', false, 'retrieveData');
          this.loading = false;
        }
      }
    },
    mounted() {
      console.log('cnstsPMSettings', cnstsPMSettings);
      this.initValidations();
    }
  };
  </script>

  <style lang="scss">
  // .toolbar-layout {
  //   justify-content: flex-end !important;  // this is effecting subUsers page. So commented this.
  // }
  .gateway_action_dialog_container {
    // .el-form-item {
    //   margin-bottom: 18px !important;
    // }
    .el-form {
      .el-form-item {
        margin-bottom: 22px !important;
      }
    }
    .el-form-item__error {
      display: none;
    }
    .allow-other-location {
      width: min-content;
      word-break: break-word;
    }
    .el-dialog {
      @include responsiveProperty(width, 35%, 35%, 35%);
    }
    .el-dialog__body {
      padding: 15px 15px;
      height: 274px !important;
    }

    .el-dialog .el-dialog__body {
      @include responsiveProperty(min-height, 220px, 220px, 250px);
    }
    .select-units,
    .select-resource-type {
      width: 100%;
    }
    // .el-input-number {
    //   width: 100%;
    // }

    .el-select .el-tag.el-tag--info .el-tag__close {
      display: inline-block !important;
      pointer-events: all !important;
    }

    .el-input--mini {
      @include responsiveProperty(
        font-size,
        $app_font_size_1,
        $app_font_size_2,
        $app_font_size_3
      );
      // width: 275px;
    }
  }
  .el-form-item {
    label {
      // width: 39%;
      // max-width: 39%;
    }
  }

  .gateway_action_dialog {
    border-radius: 10px;
    .errorwithouthighlight {
      display: flex;
      word-break: break-word !important;
      // align-items: center;
      justify-content: center;
      @include responsiveProperty(font-size, $font_size_1, $font_size_2, 16px);
      .material-icons-outlined {
        margin-top: -1px !important;
        margin-right: 4px;
        word-break: break-word !important;
      }
    }
    .errorCss {
      color: #f56c6c;
      display: flex;
      align-items: center;
      justify-content: center;
      @include responsiveProperty(font-size, $font_size_1, $font_size_2, 16px);
    }

    background: #fcfcfc;
    border-radius: 10px;

    .el-input--small .el-input__inner {
      @include responsiveProperty(width, 200px, 200px, 200px);
    }

    .el-form-item--small .el-form-item__content,
    .el-form-item--small .el-form-item__label {
      @include responsiveProperty(
        font-size,
        $app_font_size_1,
        $app_font_size_2,
        $app_font_size_3
      );
      @include responsiveProperty(min-width, 177px, 215px, 265px);
      line-height: 20px;
      display: contents;
    }
    .el-form-item__error {
      top: auto;
      @include responsiveProperty(left, 193px, 230px, 281px);
    }
    .el-form-item--small .el-form-item__error {
      @include responsiveProperty(margin-top, 31px, 31px, 41px);
    }
  }
  </style>
