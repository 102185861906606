<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: resetPassword.vue
Description: This file contains vue component which has form used for resetting the users password functionality
-->
<template>
  <el-row type="flex" justify="center" ref="resetPasswordCard">
    <el-col :span="spanValue">
      <card class="reset-password-card generic-card">
        <template slot="title">
          <h2>{{ $t("reset_password") }}</h2>
        </template>
        <template slot="form">
          <el-alert
            v-if="ehm__unhandledErrorMessage"
            :title="ehm__unhandledErrorMessage"
            type="error"
            @close="ehm__handleAlertClose"
          ></el-alert>
          <er-form
            :ref="formName"
            :model="user"
            v-loading="loading"
            element-loading-background="white"
            size="small"
            :element-loading-text="$t('Comn_auth_loading')"
          >
            <div class="form-field-label">
              {{ $t("reset_password_user_request") }}
            </div>
            <div class="user-properties-element">
              <er-form-item
                prop="password"
                :error="
                  aekmm__castErrorKeyToLang(ehm__errMessagesObject.password)
                "
              >
                <er-input
                  v-model="user.password"
                  class="er-input--password"
                  :placeholder="$t('Usrs_enter_pwd')"
                  ref="passwordField"
                  @input="pvm__getPercentage"
                  show-password
                  :style="
                    `--width:${pvm__passwordPercentage.width}px;--color:${pvm__passwordPercentage.color};--left:${pvm__passwordPercentage.left}px`
                  "
                >
                  <template slot="prepend">
                    <span class="material-icons-round icon--prefix">
                      lock
                    </span>
                  </template>
                </er-input>
              </er-form-item>
              <er-form-item
                prop="confirm_password"
                :error="
                  aekmm__castErrorKeyToLang(
                    ehm__errMessagesObject.confirm_password
                  )
                "
              >
                <er-input
                  v-model="user.confirm_password"
                  class="input-box"
                  :placeholder="`${$t('Usrs_enter_confirm_pwd')}`"
                  @keyup.native.enter="sumbitResetPasswordDetails"
                  show-password
                >
                  <template slot="prepend">
                    <span class="material-icons-round icon--prefix">
                      lock
                    </span>
                  </template>
                </er-input>
              </er-form-item>
            </div>
            <el-row class="password-validations-container">
              <span class="header">
                {{ $t("Usrs_pwd_requirement_header") }}
              </span>
              <ul class="validation-rules">
                <li
                  v-for="(validation, id) in pvm__passwordValidations"
                  :key="id"
                  :class="[
                    'validation-rules-row',
                    validation.validator() && 'is-checked'
                  ]"
                >
                  <div class="title">
                    <p>{{ ftm__capitalize(validation.content) }}</p>
                  </div>
                </li>
              </ul>
            </el-row>
          </er-form>
          <layout-toolbar class="reset-password-layout-tool-bar" justify="center">
            <er-button
              size="mini"
              class="btn_back_to_login"
              @click="rdm__navigateToSignIn"
            >
              {{ $t("Back") }} {{ $t("Comn_to") }} {{ $t("Harvest_log_in") }}
              <!-- <i class="el-icon-error"></i> -->
            </er-button>
            <er-button
              class="btn_submit"
              size="mini"
              @click="sumbitResetPasswordDetails"
            >
              {{ $t("Comn_save") }}
              <!-- <img class="img-icon" src="@/assets/login.svg" /> -->
            </er-button>
          </layout-toolbar>
        </template>
      </card>
    </el-col>
  </el-row>
</template>
<script>
import User from "@/model/user.js";
import errorHandlerMixin from "@/mixins/errorHandlerMixin.js";
import redirectsMixin from "@/mixins/redirectsMixin.js";
import filtersMixin from "@/mixins/filtersMixin.js";
import passwordValidationMixin from "../../mixins/passwordValidationMixin.vue";
import authErrorKeyMapMixin from "@/mixins/authErrorKeyMapMixin";
import card from "./card";
import {
  erButton,
  erForm,
  erInput,
  erFormItem
} from "@/components/base/basePondlogs.js";
import { mapActions } from "vuex";
export default {
  mixins: [
    errorHandlerMixin,
    redirectsMixin,
    authErrorKeyMapMixin,
    filtersMixin,
    passwordValidationMixin
  ],
  data: function() {
    return {
      user: new User(),
      ehm__errMessagesObject: new User(),
      loading: false,
      formName: "resetPasswordForm"
    };
  },
  components: {
    erButton,
    erForm,
    erFormItem,
    erInput,
    card
  },
  mounted() {
    this.setRefsForPasswordValidations(
      this.$refs.resetPasswordCard.$el,
      this.$refs.passwordField
    );
  },
  methods: {
    ...mapActions("auth", {
      resetPassword: "resetPassword"
    }),
    navigate(component) {
      this.$router.push("/" + component);
    },
    resetFields() {
      this.user = new User();
    },
    ehm__error403Handler: function() {
      this.ehm__unhandledErrorMessage = this.$t("INVALID_RESET_TOKEN");
    },
    ehm__error409Handler: function() {
      this.ehm__unhandledErrorMessage = this.$t(
        "please-choose-the-new-password-other-than-current-password"
      );
    },
    async sumbitResetPasswordDetails() {
      try {
        this.ehm__unhandledErrorMessageV2 = null;

        this.loading = true;
        const requestObj = this.$route.query;

        this.ehm__errMessagesObject = new User();
        requestObj.password = this.user.password;
        requestObj.confirm_password = this.user.confirm_password;
        await this.resetPassword({ login_id: requestObj.e || requestObj.m, ...requestObj });
        this.$notify({
          title: this.$t("Usrs_success_msg"),
          message: this.$t("successfully-resetted-your-password"),
          duration: 5000,
          type: "success"
        });
        this.$router.push("/sign-in");
      } catch (err) {
        this.ehm__errorMessages(err, false);
      } finally {
        this.btnSubmitLoading = false;
        this.loading = false;
      }
    }
  }
};
</script>
<style lang="scss">
.reset-password-card {
  height: 100%;
  .reset-password-layout-tool-bar {
      min-width: 300px;
    }
  .general-err-msg {
    font-size: 13px;
    text-align: center;
    color: red;
  }
  .el-tag {
    font-size: 1.8vh;
  }
  .el-form {
    text-align: center;
  }
  .el-form-item .el-form-item__content {
    margin: 0px !important;
  }
  .user-properties-element {
    width: 100%;
    text-align: center;
    display: inline-block;
  }
  .password-validations-container {
    margin-bottom: 40px;
    padding: 0 10px;
    .validation-rules .validation-rules-row {
      flex-basis: 50%;
    }
  }
}
</style>
