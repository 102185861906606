<template>
  <div>
  <el-row
    v-loading="loading"
    class="sub-user-details-form"
    ref="subUserDetailsForm"
  >
    <!-- class="right-panel-content-alignment" -->
    <el-form
      label-position="left"
      :key="`${editType}_${getUserId}`"
      size="mini"
      :inline="true"
    >
      <el-row class="sub-user-type-selection">
        <el-form-item
          :label="userFormLabels[0].label"
          :error="ekmm__castErrorKeyToLang(ehm__errMessagesObject.user_type)"
        >
          <er-select
            v-model="user.user_type"
            :placeholder="userFormLabels[0].placeholder"
            @change="handleChangeInUserType"
          >
            <template v-for="(value, index) in this.availableUserTypes">
              <el-option
                :key="index"
                :label="$tc(getUserTypeLangStrs[value], 1)"
                :value="value"
              ></el-option>
            </template>
          </er-select>
          <div class="sub-user-type-description" v-if="user.user_type">
            <span class="material-icons-outlined">info</span>
            <p>
              {{
                $tc(
                  $gblUAMUsrRoleToUsrRoleDetailsObj[user.user_type].description,
                  1
                )
              }}
            </p>
          </div>
        </el-form-item>
      </el-row>
      <el-row class="sub-user-basic-info">
        <layout-toolbar justify="start" class="sub-user-details-header">
          <span class="material-icons-outlined">info</span>
          <p class="sub-user-details-header__title">
            {{ $t("Usrs_necessary_info_update") }}
          </p>
        </layout-toolbar>
        <el-form-item
          prop="first_name"
          :label="userFormLabels[1].label"
          :error="ekmm__castErrorKeyToLang(ehm__errMessagesObject.first_name)"
        >
          <er-input
            v-model="user.first_name"
            class="input-box"
            :placeholder="userFormLabels[1].placeholder"
          ></er-input>
        </el-form-item>
        <el-form-item
          prop="last_name"
          :label="userFormLabels[2].label"
          :error="ekmm__castErrorKeyToLang(ehm__errMessagesObject.last_name)"
        >
          <er-input
            v-model="user.last_name"
            class="input-box"
            :placeholder="userFormLabels[2].placeholder"
          ></er-input>
        </el-form-item>
            <el-form-item
              prop="email"
              :label="userFormLabels[3].label"
            >
              <span v-if="editType === 'UPDATE'" slot="label">
                <span v-if="getUserData.email_verified" class="material-icons-outlined user-profile-verified">
                  verified
                </span>
                <span v-else class="material-icons-outlined user-profile-not-verified">
                  new_releases
                </span>
                <span>{{ userFormLabels[3].label }}</span>
              </span>
              <el-input
                v-model="user.email"
                :disabled="editType === 'UPDATE'"
                class="user-phone"
                :placeholder="userFormLabels[3].placeholder"
              >
              <template v-if="editType === 'UPDATE'" slot="append">
                <span @click="handleVerifyClick('Email')"
                  ><span class="material-icons-round">edit</span>
                </span>
              </template>
            </el-input>
            </el-form-item>
        <el-form-item
          prop="mobile"
          :label="userFormLabels[4].label"
        >
          <span v-if="editType === 'UPDATE'" slot="label">
            <span v-if="getUserData.mobile_verified" class="material-icons-outlined user-profile-verified">
              verified
            </span>
            <span v-else class="material-icons-outlined user-profile-not-verified">
              new_releases
            </span>
            <span>{{ userFormLabels[4].label }}</span>
          </span>
          <el-input
            class="user-phone"
            :disabled="editType === 'UPDATE'"
            v-model="user.mobile"
            :placeholder="userFormLabels[4].placeholder"
            autocomplete="off"
          >
          <template v-if="editType === 'UPDATE'" slot="append">
            <span @click="handleVerifyClick('Mobile')"
              ><span class="material-icons-round">edit</span>
            </span>
          </template>
        </el-input>
        </el-form-item>
      </el-row>
      <pageAccessComponent
        v-if="getUserTypeToPageAccessLayout(user.user_type).length"
        :layout="getUserTypeToPageAccessLayout(user.user_type)"
        :locations-list="locations"
        :permissions-list="$gblUAMPermissionsToPermissionDetailsList"
        :user-details="user"
        :editable="user.user_type === 'ACCOUNT_MANAGER_SUB_USER'? false : true"
        @locations-changed="handleLocationsChange"
        @permissions-changed="handlePermissionsChange"
      ></pageAccessComponent>
    </el-form>
    <layout-toolbar
      type="flex"
      justify="end"
      class="btn-container right-panel-content-alignment"
    >
      <er-button
        size="mini"
        btnType="save"
        :showLabel="true"
        :showIcon="true"
        @click="submitForm"
        :loading="loading"
      ></er-button>
      <er-button
        size="mini"
        btnType="cancel"
        :showLabel="true"
        @click="resetForm"
        :loading="loading"
      ></er-button>
    </layout-toolbar>
  </el-row>
  <OTPVerificationDialog
      class="OTPVerificationDialog"
      :dialogVisible="dialogVisible"
      :dailogTitle="dailogTitle"
      :parentBtnLoading="btnLoading"
      :userData="user"
      :includeUserid="true"
      :ehm__errMessagesObjectCopy="ehm__errMessagesObject"
      @handleCloseDialog="handleCloseDialog"
      @submitUpdatedMobile="submitUpdatedMobile"
      @handleSendOtp="handleSendOtp"
      @handleVerifyOtp="handleVerifyOtp"
    ></OTPVerificationDialog>
  </div>
</template>

<script>
import User from "@/model/user";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import errorKeyMapMixin from "@/mixins/errorKeyMapMixin";
import { mapGetters, mapActions } from "vuex";
import {
  userTypes,
  FTPermissionsToActions,
  groupPermissions,
  customerUserTypes
} from "@/middleware/pageAccessManager";
import { UAM_UI_STATES } from "@/constants/uiStates";
import passwordValidationMixin from "@/mixins/passwordValidationMixin.vue";
import pageAccessComponent from "./pageAccessComponent";
import OTPVerificationDialog from "../../settings/OTPVerificationDialog.vue";
export default {
  props: ["editType"],
  inject: ["parentConfig"],
  mixins: [errorHandlerMixin, errorKeyMapMixin, passwordValidationMixin],
  components: { pageAccessComponent, OTPVerificationDialog },
  data() {
    return {
      loading: false,
      user: new User(),
      ehm__errMessagesObject: new User(),
      FTPermissionsToActions,
      ekmm__backndfieldToFieldNameMap: {
        email: "Comn_email"
      },
      ekmm__backndfieldToInvalidMap: {
        email: {
          INVALID_USER_DATA: "Usrs_email_already_exists"
        }
      },
      userTypeToPermissionLayout: {},
      createUserTypes: [
        userTypes.pond_owner,
        userTypes.pond_manager,
        userTypes.pond_worker,
        userTypes.pond_technician
      ],
      editUserTypes: [
        userTypes.pond_owner,
        userTypes.pond_manager,
        userTypes.pond_worker,
        userTypes.pond_technician,
        userTypes.no_access
      ],
      userPayloadSchema: [
        {
          prop: "first_name",
          user_type: customerUserTypes,
          action: ["CREATE", "UPDATE"]
        },
        {
          prop: "last_name",
          user_type: customerUserTypes,
          action: ["CREATE", "UPDATE"]
        },
        {
          prop: "email",
          user_type: customerUserTypes,
          action: ["CREATE", "UPDATE"]
        },
        {
          prop: "user_type",
          user_type: customerUserTypes,
          action: ["CREATE", "UPDATE"]
        },
        {
          prop: "allowed_locations",
          user_type: [
            userTypes.pond_manager,
            userTypes.pond_worker,
            userTypes.pond_technician
          ],
          action: ["CREATE", "UPDATE"]
        },
        {
          prop: "permissions",
          user_type: [userTypes.pond_worker, userTypes.pond_technician],
          action: ["CREATE", "UPDATE"]
        }
      ],
      dialogVisible: false,
      oldMobile: '',
      oldEmail: '',
      dailogTitle: {
        title: '',
        oldData: '',
        name: '',
        maskValue: '',
        oldMobile: '',
        oldEmail: ''
      },
      btnLoading: false
    };
  },
  watch: {
    getUserId: function () {
      this.ehm__clearErrorMessages(new User());
      this.handleChangeInUser();
    }
  },
  computed: {
    ...mapGetters("userAccessManagement", {
      getSidePanelState: "getSidePanelState"
    }),
    ...mapGetters("user", {
      getUserLocations: "getUserLocations",
      getCurrUserLocation: "getCurrUserLocation"
    }),
    locations() {
      return this.$lodash.cloneDeep(this.getUserLocations);
    },
    locationIds() {
      return this.locations.map((location) => location._id);
    },
    userFormLabels() {
      return [
        {
          label: this.$t("Usrs_role_select"),
          placeholder: this.$t("Usrs_select_atleast_one_role")
        },
        {
          label: this.$t("Usrs_first_name"),
          placeholder: this.$t("Usrs_enter_first_name")
        },
        {
          label: this.$t("Usrs_last_name"),
          placeholder: this.$t("Usrs_enter_last_name")
        },
        {
          label: this.$t("Comn_email_address"),
          placeholder: this.$t("Usrs_enter_email_addr")
        },
        {
          label: this.$t("Comn_mobile_num"),
          placeholder: this.$t("Usrs_enter_mobile_num")
        },
        {
          label: this.$t("Usrs_pwd"),
          placeholder: this.$t("Usrs_enter_pwd")
        },
        {
          label: this.$t("Comn_confirm_pwd"),
          placeholder: this.$t("Usrs_enter_confirm_pwd")
        }
      ];
    },
    availableUserTypes() {
      return this.editType === "UPDATE"
        ? this.editUserTypes
        : this.createUserTypes;
    },
    getUserTypeLangStrs() {
      return this.parentConfig.USER_TYPE;
    },
    getUserData() {
      return this.getSidePanelState.userDetails;
    },
    getUserId() {
      return this.getUserData._id;
    },
    getUAMTabData() {
      return this.parentConfig.uamTabData;
    }
  },
  created() {
    this.handleChangeInUser();
  },
  mounted() {
    this.setRefsForPasswordValidations(
      this.$refs.subUserDetailsForm.$el,
      this.$refs.passwordField
    );
  },
  methods: {
    ...mapActions("userAccessManagement", {
      restoreToIntialState: "restoreToIntialState",
      updateSubUser: "updateSubUser",
      createSubUser: "createSubUser"
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    handleVerifyClick(data) {
      this.oldMobile = this.$lodash.cloneDeep(this.user).mobile;
      this.oldEmail = this.$lodash.cloneDeep(this.user).email;
      this.dialogVisible = true;
      this.dailogTitle.name = data;
      this.dailogTitle.oldMobile = this.oldMobile;
      this.dailogTitle.oldEmail = this.oldEmail;
      this.dailogTitle.title = data === "Mobile" ? this.$t('update_your_mobile') : this.$t('update_your_email');
      this.dailogTitle.oldData = data === "Mobile" ? this.$t('comn_old_mobile') : this.$t('comn_old_email');
      this.dailogTitle.maskValue = data === "Mobile" ? this.maskPhoneNumber(this.oldMobile) : this.maskPhoneNumber(this.oldEmail)
    },
    handleCloseDialog() {
      this.dialogVisible = false;
    },
    maskPhoneNumber(data) {
      const firstFour = data.substring(0, 2);
      const lastFour = data.substring(7);
      return `${firstFour}XXXX${lastFour}`;
    },
    async handleSendOtp(data, userData) {
      this.dailogTitle.title = data === "Mobile" ? this.$t('verify_your_mobile') : this.$t('verify_your_email');
      this.dailogTitle.maskValue = data === "Mobile" ? this.maskPhoneNumber(userData.mobile) : this.maskPhoneNumber(userData.email)
    },
    async handleVerifyOtp(data) {
      await this.handleCloseDialog();
      this.$emit("statusChange", {
          status: "SUCCESS",
          userId: this.user._id
        });
    },
    async submitUpdatedMobile(userData) {
      try {
        this.btnLoading = true;
        this.$gblUAMCanUserEdit(this.getUAMTabData);
        this.validateForPermissionsAndLocations();
        delete userData.phone;
        this.ehm__clearErrorMessages(new User());
        const response = await this.updateSubUser({
              userId: userData._id,
              payload: userData
            });
        this.$notify({
          title: this.$t("Usrs_success_msg"),
          message: this.$t('Usrs_usr_details_upd_successfully'),
          duration: 5000,
          type: "success"
        });
        this.$emit("statusChange", {
          status: "SUCCESS",
          userId: this.editType === "CREATE" ? response._id : this.user._id
        });
      } catch (err) {
        this.ehm__errorMessages(err, true);
        if (err.type === "FAIL_TO_SAVE") return;
        this.$emit("statusChange", { status: "FAILED" });
      } finally {
        this.btnLoading = false;
      }
    },
    getPayload(user) {
      return (this.editType === 'UPDATE' ? this.userPayloadSchema : [{
          prop: "mobile",
          user_type: customerUserTypes,
          action: ["CREATE", "UPDATE"]
        }, ...this.userPayloadSchema]).reduce((userObj, currProp) => {
        const conditions = [
          currProp.action.includes(this.editType) &&
            currProp.user_type.includes(user.user_type)
        ];
        if (conditions.some((x) => x)) {
          userObj[currProp.prop] = this.user[currProp.prop];
        }
        return userObj;
      }, {});
    },
    getUserTypeToPageAccessLayout(userType) {
      switch (userType) {
        case userTypes.pond_owner:
        case userTypes.admin:
        case userTypes.no_access:
        case userTypes.skretting_technician:
          return [];
        case userTypes.pond_manager:
          return ["locations"];
        default:
          return ["permissions", "locations"];
      }
    },
    handleChangeInUserType() {
      const isLocationsEmpty = this.user.allowed_locations.length === 0;
      if (isLocationsEmpty) {
        this.user.allowed_locations = [this.getCurrUserLocation._id];
      }
      const isValidUserType = [
        [userTypes.pond_technician, userTypes.pond_worker].includes(
          this.user.user_type
        )
      ].every((x) => x);
      if (isValidUserType && this.user.permissions.length === 0) {
        this.user.permissions =
          this.$gblUAMGetDefaultPermissions[this.user.user_type];
        this.user.parsedPermissions = Object.assign(
          this.user.parsedPermissions || {},
          groupPermissions(this.user.permissions)
        );
      }
    },
    ehm__error403Handler(err) {
      const errorData = err.response.data;
      if (errorData.error_code === "ACCOUNT_ALREADY_TAKEN") {
        this.$notify({
          title: this.$t("failed"),
          message: this.dailogTitle.name === 'Mobile' ? this.$t("mobile_already_Verified") : this.$t("Email_already_Verified"),
          type: "error"
        });
      }
    },
    handleChangeInUser() {
      this.user = this.$lodash.cloneDeep(this.getUserData);
      this.user.mobile = this.user.mobile || this.user.phone;
      if (this.editType === "CREATE") {
        this.user.user_type = userTypes.pond_owner;
      }
    },
    handleLocationsChange(changedLocations) {
      this.user.allowed_locations = changedLocations;
    },
    handlePermissionsChange(changedPermissions, parsedPermissions) {
      this.user.permissions = changedPermissions;
      this.user.parsedPermissions = Object.assign(
        this.user.parsedPermissions || {},
        groupPermissions(this.user.permissions)
      );
    },
    async resetForm() {
      this.user = new User();
      this.user.allowed_locations = [this.locationIds[0]];
      await this.restoreToIntialState({
        shallFetchData: false,
        sidePanelUserId: this.getUserData._id,
        sidePanelUIState: UAM_UI_STATES.DISPLAY_SUB_USER_DETAILS
      });
    },
    ehm__error409Handler: function (err) {
      if (err.response.data.errors != null) {
        const errorDetails = err.response.data.errors.details;
        errorDetails.forEach((el, index) => {
          this.ehm__unhandledErrorMessage +=
            (index === 0 ? "" : ",") +
            el.message +
            (errorDetails.length - 1 === index ? "" : ",");
        });
      } else {
        err.response.data.key = "email";
        this.ehm__errMessagesObject.email = err.response.data;
      }
    },
    validateForPermissionsAndLocations() {
      const errors = [
        {
          field: this.$t("Comn_permissions"),
          value: this.user.permissions,
          key: "permissions"
        },
        {
          field: this.ftm__capitalize(this.$tc("Comn_location.case.lower", 2)),
          value: this.user.allowed_locations,
          key: "locations"
        }
      ]
        .filter((property) => {
          return this.getUserTypeToPageAccessLayout(
            this.user.user_type
          ).includes(property.key);
        })
        .reduce((acc, property) => {
          if (property.value.length === 0) {
            acc.push({
              message: this.$t("Comn_must_not_be_empty", {
                field: property.field
              })
            });
          }
          return acc;
        }, []);
      if (errors.length > 0) {
        throw {
          type: "FAIL_TO_SAVE",
          errors
        };
      }
    },
    async submitForm() {
      try {
        this.$gblUAMCanUserEdit(this.getUAMTabData);
        this.validateForPermissionsAndLocations();
        this.$emit("loading", true);
        this.user.first_name = this.upm__trimSpacesInStr(this.user.first_name);
        this.user.last_name = this.upm__trimSpacesInStr(this.user.last_name);
        const modeToStoreFunc = {
          CREATE: {
            func: "createSubUser",
            params: this.getPayload(this.user),
            success_message: "Usrs_sub_usr_creat_succe"
          },
          UPDATE: {
            func: "updateSubUser",
            params: {
              userId: this.user._id,
              payload: this.getPayload(this.user)
            },
            success_message: "Usrs_usr_details_upd_successfully"
          }
        };
        this.ehm__clearErrorMessages(new User());
        const response = await this[modeToStoreFunc[this.editType].func](
          modeToStoreFunc[this.editType].params
        );
        this.$notify({
          title: this.$t("Usrs_success_msg"),
          message: this.$t(modeToStoreFunc[this.editType].success_message),
          duration: 5000,
          type: "success"
        });
        if (this.editType === "CREATE") {
          this.mixPanelEventGenerator({ eventName: "Settings - Users - Add User - Save" });
        } else {
          this.mixPanelEventGenerator({ eventName: "Settings - Users - Edit User - Save" });
        }
        this.$emit("statusChange", {
          status: "SUCCESS",
          userId: this.editType === "CREATE" ? response._id : this.user._id
        });
      } catch (err) {
        this.ehm__errorMessages(err, true);
        if (err.type === "FAIL_TO_SAVE") return;
        this.$emit("statusChange", { status: "FAILED" });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/_passwordvalidation.scss";
.sub-user-details-form {
  @include password-validation-mixin;
  &::v-deep .el-form-item {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    // align-items: center;
    justify-content: center;
    // $label-width: 100px;
    .el-form-item__label {
      @include responsiveProperty(width, 100px, 120px, 150px);
      @include small-text;
    }
    .el-form-item__content {
      @include responsiveProperty(width, 57%, 50%, 45%);
      .el-input,
      .el-input .el-input__inner,
      .el-select {
        @include small-text;
        width: 100%;
      }
    }
  }
  &::v-deep .er-input.er-input--password:after {
    content: "";
    position: absolute;
    width: var(--width);
    background-color: var(--color);
    height: 2px;
    bottom: 0px;
    left: var(--left);
    padding-left: 0px;
    transition: width 0.5s ease-out;
  }
  &::v-deep .sub-user-details-header {
    padding: 5px 15px;
    font-weight: 700;
    @include normal-text;
  }
  &::v-deep .sub-user-type-selection {
    padding-top: 25px;
    .el-form-item .el-form-item__content {
      @include responsiveProperty(width, 57%, 50%, 45%);
    }

    .el-form-item__content {
      // display: inline-flex;
      // flex-direction: column;
    }
    .sub-user-type-description {
      display: inline-flex;
      flex-direction: row;
      @include v-small-text;
      white-space: break-word;
      line-height: 1;
      margin-top: 10px;
      & > * {
        align-self: center;
      }
      .material-icons-outlined {
        margin-right: 5px;
        @include small-text;
      }
    }
  }
}
</style>
<style lang="scss">
.sub-user-basic-info {
  .user-phone {
    .el-input-group__append {
      color: #000000;
      cursor: pointer;
      padding: 0px 5px;
    }
    .material-icons-round {
      font-size: 18px;
    }
  }
  .user-profile-verified {
    font-size: 14px;
    // padding-top: 6px;
    color: #57e657;
  }
  .user-profile-not-verified {
    font-size: 14px;
    // padding-top: 6px;
    color: #ed6c41;
  }
}
</style>
