<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: signUpStatus.vue
Description: This file contains vue component which used to display the status of the user registration process
-->
<template>
  <el-row type="flex" justify="center" class="sign-up-status-card">
    <el-col :span="spanValue">
      <card>
        <template slot="title">
          <template v-if="message_status === 'success'">
            <h2>{{ $t("signed-up-successfully") }}</h2>
          </template>
          <template v-if="page === 'email_verify'">
            <h2>{{ $t("verify_link_expire_title") }}</h2>
          </template>
        </template>
        <div class="sign-up-status-btn-container" slot="form">
          <center>
            <div class="sign-up-field-label">{{ $t(message_key) }}</div>
            <br />
            <er-button
              size="small"
              type="plain"
              class="btn_back_to_login"
              @click="rdm__navigateToSignIn"
              style="margin-bottom: 20px"
              >{{ $t("Back") }} {{ $t("Comn_to") }}
              {{ $t("Harvest_log_in") }}</er-button
            >
            <br />
            <div class="sign-up-field-label">
              {{ $t("text_above_resend_verification_link_button") }}
            </div>
            <br />
            <er-button
              @click="handleResendEmail"
              type="primary"
              size="small"
              round
              >{{ $t("resend_verify_link") }}</er-button
            >
          </center>
        </div>
      </card>
    </el-col>
  </el-row>
</template>

<script>
import card from "./card";
import redirectsMixin from "@/mixins/redirectsMixin.js";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
export default {
  mixins: [redirectsMixin, errorHandlerMixin],
  props: {
    message_key: {
      default: "comfirm_link_request_message"
    },
    message_status: {
      default: "success"
    },
    page: {
      default: "sign_up"
    }
  },
  components: {
    card
  },
  data: function() {
    return {};
  },
  methods: {
    handleResendEmail() {
      this.$router.push("/resend-link");
    }
  }
};
</script>

<style lang="scss">
.sign-up-status-card {
  .sign-up-status-btn-container {
    min-width: 300px;
  }
  .sign-up-field-label {
    color: #909399;
    margin-bottom: 5px;
  }
  .el-button--small, .el-button--small {
    padding: 6px 15px;
  }
  .el-col {
    height: 100%;
  }

  .el-card {
    height: 100%;
  }
}
</style>
