import gatewaySettingsConfig from "../device-settings/gateway-settings";
import pgSettingsConfig from "../device-settings/pg-settings";
import pmSettingsConfig from "../device-settings/pm-settings";
import stSettingsConfig from "../device-settings/st-settings";
import errorAlertStringMapping from "@/utils/errorAlertStringMapping.js";
const isIntersect = function(arr1 = [], value) {
  return () => arr1.includes(value);
};
const getTableColumnsConfig = function(logType) {
  const columns = [
    {
      headerName: "Client",
      field: "client",
      colId: "client",
      sortable: false,
      filter: true,
      required: true,
      visible: isIntersect(
        [
          "SCHEDULES",
          "PM_SETTINGS",
          "ST_SETTINGS",
          "PG_SETTINGS",
          "GT_SETTINGS",
          "POND_DEVICES", // "POND_PM_MAP",
          "ABW",
          // "PROFILE_SETTINGS",
          "PREFERENCES_SETTINGS",
          "USER_SETTINGS",
          "POND_SETTINGS",
          "POND_ALERT",
          "USER_LOCATIONS",
          "MANUAL_POND_FEEDINGS",
          "PONDS_LAB_TEST",
          "PONDS_MEDICINES",
          "POND_SCHEDULE_SETS",
          "PONDS_HARVEST",
          "USER_LABS",
          "RESOURCE_VENDORS",
          "USER_RESOURCES",
          "STOCK_FEEDS",
          "STOCK_MEDICINES",
          "PONDS_AQUASIMS"
        ],
        logType
      )
    },
    {
      headerName: "IP Address",
      field: "ip_address",
      colId: "ip",
      sortable: false,
      filter: true,
      required: true,
      visible: isIntersect(
        [
          "SCHEDULES",
          "PM_SETTINGS",
          "ST_SETTINGS",
          "PG_SETTINGS",
          "GT_SETTINGS",
          "POND_DEVICES", // "POND_PM_MAP",
          "ABW",
          // "PROFILE_SETTINGS",
          "PREFERENCES_SETTINGS",
          "USER_SETTINGS",
          "POND_SETTINGS",
          "POND_ALERT",
          "USER_LOCATIONS",
          "MANUAL_POND_FEEDINGS",
          "PONDS_LAB_TEST",
          "PONDS_MEDICINES",
          "POND_SCHEDULE_SETS",
          "PONDS_HARVEST",
          "USER_LABS",
          "RESOURCE_VENDORS",
          "USER_RESOURCES",
          "STOCK_FEEDS",
          "STOCK_MEDICINES",
          "PONDS_AQUASIMS"
        ],
        logType
      )
    },
    {
      headerName: "OS",
      field: "os",
      colId: "os",
      sortable: false,
      filter: true,
      required: false,
      visible: isIntersect(
        [
          "SCHEDULES",
          "PM_SETTINGS",
          "ST_SETTINGS",
          "PG_SETTINGS",
          "GT_SETTINGS",
          "POND_DEVICES", // "POND_PM_MAP",
          "ABW",
          // "PROFILE_SETTINGS",
          "PREFERENCES_SETTINGS",
          "USER_SETTINGS",
          "POND_SETTINGS",
          "POND_ALERT",
          "USER_LOCATIONS",
          "MANUAL_POND_FEEDINGS",
          "PONDS_LAB_TEST",
          "PONDS_MEDICINES",
          "POND_SCHEDULE_SETS",
          "PONDS_HARVEST",
          "USER_LABS",
          "RESOURCE_VENDORS",
          "USER_RESOURCES",
          "STOCK_FEEDS",
          "STOCK_MEDICINES",
          "PONDS_AQUASIMS"
        ],
        logType
      )
    },
    {
      headerName: "Client App version",
      field: "client_app_version",
      colId: "client_app_version",
      sortable: false,
      filter: true,
      required: false,
      visible: isIntersect(
        [
          "SCHEDULES",
          "PM_SETTINGS",
          "ST_SETTINGS",
          "PG_SETTINGS",
          "GT_SETTINGS",
          "POND_DEVICES", // "POND_PM_MAP",
          "ABW",
          // "PROFILE_SETTINGS",
          "PREFERENCES_SETTINGS",
          "USER_SETTINGS",
          "POND_SETTINGS",
          "POND_ALERT",
          "USER_LOCATIONS",
          "MANUAL_POND_FEEDINGS",
          "PONDS_LAB_TEST",
          "PONDS_MEDICINES",
          "POND_SCHEDULE_SETS",
          "PONDS_HARVEST",
          "USER_LABS",
          "RESOURCE_VENDORS",
          "USER_RESOURCES",
          "STOCK_FEEDS",
          "STOCK_MEDICINES",
          "PONDS_AQUASIMS"
        ],
        logType
      )
    },
    {
      headerName: "Updated Date",
      field: "date",
      colId: "date",
      sortable: true,
      filter: "agGridDateTimeCompareFilter",
      required: true,
      visible: isIntersect(
        [
          "SCHEDULES",
          "PM_SETTINGS",
          "ST_SETTINGS",
          "PG_SETTINGS",
          "GT_SETTINGS",
          "POND_DEVICES", // "POND_PM_MAP",
          // "POND_ST_MAP",
          // "POND_PG_MAP",
          "ABW",
          // "PROFILE_SETTINGS",
          "PREFERENCES_SETTINGS",
          "USER_SETTINGS",
          "POND_SETTINGS",
          "POND_ALERT",
          "USER_LOCATIONS",
          "MANUAL_POND_FEEDINGS",
          "PONDS_LAB_TEST",
          "PONDS_MEDICINES",
          "POND_SCHEDULE_SETS",
          "PONDS_HARVEST",
          "USER_LABS",
          "RESOURCE_VENDORS",
          "USER_RESOURCES",
          "STOCK_FEEDS",
          "STOCK_MEDICINES",
          "PONDS_AQUASIMS"
        ],
        logType
      )
    },
    {
      headerName: "Action",
      field: "action",
      colId: "action",
      sortable: false,
      filter: true,
      required: true,
      visible: isIntersect(
        [
          "SCHEDULES",
          "PM_SETTINGS",
          "ST_SETTINGS",
          "PG_SETTINGS",
          "GT_SETTINGS",
          "POND_DEVICES", // "POND_PM_MAP",
          // "POND_ST_MAP",
          // "POND_PG_MAP",
          "ABW",
          // "PROFILE_SETTINGS",
          "PREFERENCES_SETTINGS",
          "USER_SETTINGS",
          "POND_SETTINGS",
          "POND_ALERT",
          "USER_LOCATIONS",
          "MANUAL_POND_FEEDINGS",
          "PONDS_LAB_TEST",
          "PONDS_MEDICINES",
          "POND_SCHEDULE_SETS",
          "PONDS_HARVEST",
          "USER_LABS",
          "RESOURCE_VENDORS",
          "USER_RESOURCES",
          "STOCK_FEEDS",
          "STOCK_MEDICINES",
          "PONDS_AQUASIMS"
        ],
        logType
      )
    },
    {
      headerName: "Device Title",
      field: "device_title",
      colId: "device_title",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(
        [
          "PM_SETTINGS",
          "ST_SETTINGS",
          "PG_SETTINGS",
          "GT_SETTINGS",
          "POND_DEVICES" // "POND_PM_MAP",
          // "POND_ST_MAP",
          // "POND_PG_MAP"
        ],
        logType
      )
    },
    {
      headerName: "Device Code",
      field: "device_code",
      colId: "device_code",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(
        [
          "PM_SETTINGS",
          "ST_SETTINGS",
          "PG_SETTINGS",
          "GT_SETTINGS",
          "POND_DEVICES" // "POND_PM_MAP",
          // "POND_ST_MAP",
          // "POND_PG_MAP"
        ],
        logType
      )
    },
    {
      headerName: "Start Time",
      field: "start_time",
      colId: "start_time",
      sortable: true,
      required: true,
      filter: "agGridDateTimeCompareFilter",
      visible: isIntersect(["SCHEDULES", "ST_SETTINGS"], logType)
    },
    {
      headerName: "End Time",
      field: "end_time",
      colId: "end_time",
      sortable: true,
      required: true,
      filter: "agGridDateTimeCompareFilter",
      visible: isIntersect(["SCHEDULES", "ST_SETTINGS"], logType)
    },
    {
      headerName: "Total Feed",
      field: "feed",
      colId: "feed",
      sortable: true,
      required: true,
      filter: "agNumberColumnFilter",
      visible: isIntersect(["SCHEDULES"], logType)
    },
    {
      headerName: "Dispensed Feed",
      field: "dispensed_feed",
      colId: "dispensed_feed",
      sortable: true,
      required: true,
      filter: "agNumberColumnFilter",
      visible: isIntersect(["SCHEDULES"], logType)
    },
    {
      headerName: "Timeslot Status",
      field: "schedule_status",
      colId: "schedule_status",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["SCHEDULES"], logType)
    },
    {
      headerName: "Mode",
      field: "schedule_mode",
      colId: "schedule_mode",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["SCHEDULES"], logType)
    },
    {
      headerName: "OCF",
      field: "ocf",
      colId: "ocf",
      sortable: true,
      required: true,
      filter: "agNumberColumnFilter",
      visible: isIntersect(["SCHEDULES"], logType)
    },
    {
      headerName: "Feed Gap",
      field: "feed_gap",
      colId: "feed_gap",
      sortable: true,
      required: true,
      filter: "agNumberColumnFilter",
      visible: isIntersect(["SCHEDULES"], logType)
    },
    {
      headerName: "User Type",
      field: "user_type",
      colId: "user_type",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(
        [
          "SCHEDULES",
          "POND_DEVICES", // "POND_PM_MAP",
          "PM_SETTINGS",
          "ST_SETTINGS",
          "PG_SETTINGS",
          "GT_SETTINGS",
          // "POND_ST_MAP",
          // "POND_PG_MAP",
          "ABW",
          // "PROFILE_SETTINGS",
          "PREFERENCES_SETTINGS",
          "USER_SETTINGS",
          "POND_SETTINGS",
          "POND_ALERT",
          "USER_LOCATIONS",
          "MANUAL_POND_FEEDINGS",
          "PONDS_LAB_TEST",
          "PONDS_MEDICINES",
          "POND_SCHEDULE_SETS",
          "PONDS_HARVEST",
          "USER_LABS",
          "RESOURCE_VENDORS",
          "USER_RESOURCES",
          "STOCK_FEEDS",
          "STOCK_MEDICINES",
          "PONDS_AQUASIMS"
        ],
        logType
      )
    },
    {
      headerName: "User Name",
      field: "user_name",
      colId: "user_name",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(
        [
          "SCHEDULES",
          "POND_DEVICES", // "POND_PM_MAP",
          "PM_SETTINGS",
          "ST_SETTINGS",
          "PG_SETTINGS",
          "GT_SETTINGS",
          // "POND_ST_MAP",
          // "POND_PG_MAP",
          "ABW",
          // "PROFILE_SETTINGS",
          "PREFERENCES_SETTINGS",
          "USER_SETTINGS",
          "POND_SETTINGS",
          "POND_ALERT",
          "USER_LOCATIONS",
          "MANUAL_POND_FEEDINGS",
          "PONDS_LAB_TEST",
          "PONDS_MEDICINES",
          "POND_SCHEDULE_SETS",
          "PONDS_HARVEST",
          "USER_LABS",
          "RESOURCE_VENDORS",
          "USER_RESOURCES",
          "STOCK_FEEDS",
          "STOCK_MEDICINES",
          "PONDS_AQUASIMS"
        ],
        logType
      )
    },
    {
      headerName: "User Email Id",
      field: "user_email",
      colId: "user_email",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(
        [
          "SCHEDULES",
          "POND_DEVICES", // "POND_PM_MAP",
          "PM_SETTINGS",
          "ST_SETTINGS",
          "PG_SETTINGS",
          "GT_SETTINGS",
          // "POND_ST_MAP",
          // "POND_PG_MAP",
          "ABW",
          // "PROFILE_SETTINGS",
          "PREFERENCES_SETTINGS",
          "USER_SETTINGS",
          "POND_SETTINGS",
          "POND_ALERT",
          "USER_LOCATIONS",
          "MANUAL_POND_FEEDINGS",
          "PONDS_LAB_TEST",
          "PONDS_MEDICINES",
          "POND_SCHEDULE_SETS",
          "PONDS_HARVEST",
          "USER_LABS",
          "RESOURCE_VENDORS",
          "USER_RESOURCES",
          "STOCK_FEEDS",
          "STOCK_MEDICINES",
          "PONDS_AQUASIMS"
        ],
        logType
      )
    },
    {
      headerName: "Device Type",
      field: "device_type",
      colId: "device_type",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["POND_ALERT", "POND_DEVICES"], logType)
    },
    {
      headerName: "Error Code",
      field: "error_code",
      colId: "error_code",
      sortable: true,
      filter: true,
      required: false,
      visible: isIntersect(["POND_ALERT"], logType),
      valueFormatter: params => {
        return errorAlertStringMapping.mapKeyToString(params.value);
      }
    },
    {
      headerName: "Date Format",
      field: "profile_date_format",
      colId: "profile_date_format",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["PREFERENCES_SETTINGS"], logType)
    },
    {
      headerName: "Time Format",
      field: "profile_time_format",
      colId: "profile_time_format",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["PREFERENCES_SETTINGS"], logType)
    },
    {
      headerName: "Biomass Units",
      field: "profile_biomass_units",
      colId: "profile_biomass_units",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["PREFERENCES_SETTINGS"], logType)
    },
    {
      headerName: "Biomass / Pond Area Units",
      field: "profile_biomass_per_area_units",
      colId: "profile_biomass_per_area_units",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["PREFERENCES_SETTINGS"], logType),
      valueFormatter: params => {
        // `data.biomass_per_area_units.biomass_units/data.biomass_per_area_units.pond_area_units`
        console.log(params);
        if (params.value?.biomass_units && params.value?.pond_area_units) {
          return `${params.value.biomass_units}/${params.value.pond_area_units}`;
        }
        return ``;
      }
    },
    {
      headerName: "Pond Area Units",
      field: "profile_pond_area_units",
      colId: "profile_pond_area_units",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["PREFERENCES_SETTINGS"], logType)
    },
    {
      headerName: "Device Name",
      field: "profile_device_name",
      colId: "profile_device_name",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["PREFERENCES_SETTINGS"], logType)
    },
    {
      headerName: "Feed Gap",
      field: "profile_feed_gap",
      colId: "profile_feed_gap",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["PREFERENCES_SETTINGS"], logType)
    },
    {
      headerName: "Location",
      field: "profile_location",
      colId: "profile_location",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["PREFERENCES_SETTINGS"], logType)
    },
    {
      headerName: "Default Dashboard Tab",
      field: "profile_default_dashboard_tab",
      colId: "profile_default_dashboard_tab",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["PREFERENCES_SETTINGS"], logType)
    },
    {
      headerName: "Sub User Name",
      field: "sub_user_name",
      colId: "sub_user_name",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["USER_SETTINGS"], logType)
    },
    {
      headerName: "Sub User Email",
      field: "sub_user_email",
      colId: "sub_user_email",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["USER_SETTINGS"], logType)
    },
    {
      headerName: "First Name",
      field: "user_registration_first_name",
      colId: "user_registration_first_name",
      sortable: true,
      filter: true,
      required: false,
      visible: isIntersect(["USER_SETTINGS"], logType)
    },
    {
      headerName: "Last Name",
      field: "user_registration_last_name",
      colId: "user_registration_last_name",
      sortable: true,
      filter: true,
      required: false,
      visible: isIntersect(["USER_SETTINGS"], logType)
    },
    {
      headerName: "Phone",
      field: "user_registration_phone",
      colId: "user_registration_phone",
      sortable: true,
      filter: true,
      required: false,
      visible: isIntersect(["USER_SETTINGS"], logType)
    },
    {
      headerName: "Email",
      field: "user_registration_email",
      colId: "user_registration_email",
      sortable: true,
      filter: true,
      required: false,
      visible: isIntersect(["USER_SETTINGS"], logType)
    },
    {
      headerName: "User Type",
      field: "user_registration_user_type",
      colId: "user_registration_user_type",
      sortable: true,
      filter: true,
      required: false,
      visible: isIntersect(["USER_SETTINGS"], logType)
    },
    {
      headerName: "Country",
      field: "profile_country",
      colId: "profile_country",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["USER_SETTINGS"], logType)
    },
    {
      headerName: "Timezone",
      field: "profile_timezone",
      colId: "profile_timezone",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["USER_SETTINGS"], logType)
    },
    {
      headerName: "Status",
      field: "profile_status",
      colId: "profile_status",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["USER_SETTINGS"], logType)
    },
    {
      headerName: "Resolved By",
      field: "resolved_by",
      colId: "resolved_by",
      sortable: true,
      filter: true,
      required: false,
      visible: isIntersect(["POND_ALERT"], logType)
    },
    {
      headerName: "Resolved Date",
      field: "resolved_date",
      colId: "resolved_date",
      sortable: true,
      filter: true,
      required: false,
      visible: isIntersect(["POND_ALERT"], logType)
      // valueFormatter: (params) => {
      //   console.log("params.value", params);
      //   return this.formatDateMethod(params.value);
      // }
    },
    {
      headerName: "Verification Status",
      field: "user_registration_verification_status",
      colId: "user_registration_verification_status",
      sortable: true,
      filter: true,
      required: false,
      visible: isIntersect(["USER_SETTINGS"], logType)
      // valueFormatter: (params) => {
      //   console.log("params.value", params);
      //   return this.formatDateMethod(params.value);
      // }
    },

    {
      headerName: "Resolved Notes",
      field: "resolved_notes",
      colId: "resolved_notes",
      sortable: true,
      filter: true,
      required: false,
      visible: isIntersect(["POND_ALERT"], logType)
    },
    {
      headerName: "On Time",
      field: "on_time",
      colId: "on_time",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["SCHEDULES"], logType)
    },
    {
      headerName: "Off Time",
      field: "off_time",
      colId: "off_time",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["SCHEDULES"], logType)
    },
    {
      headerName: "Gateway Id",
      field: "gateway_id",
      colId: "gateway_id",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["SCHEDULES"], logType)
    },
    {
      headerName: "Alias Name",
      field: "alias_name",
      colId: "alias_name",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["PM_SETTINGS"], logType)
    },
    {
      headerName: "kg Dispense Time",
      field: "kg_dispense_time",
      colId: "kg_dispense_time",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["PM_SETTINGS"], logType)
    },
    {
      headerName: "Mode",
      field: "mode",
      colId: "mode",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["PM_SETTINGS"], logType)
    },
    {
      headerName: "Hopper Type",
      field: "hopper_type",
      colId: "hopper_type",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["PM_SETTINGS"], logType)
    },
    {
      headerName: "Device Ui Edit",
      field: "device_ui_edit",
      colId: "device_ui_edit",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["PM_SETTINGS"], logType)
    },
    {
      headerName: "Alias Name",
      field: "alias_name",
      colId: "alias_name",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["ST_SETTINGS"], logType)
    },
    {
      headerName: "Pause Start",
      field: "pause_start",
      colId: "pause_start",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["ST_SETTINGS"], logType)
    },
    {
      headerName: "Pause End",
      field: "pause_end",
      colId: "pause_end",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["ST_SETTINGS"], logType)
    },
    {
      headerName: "Feed Limit",
      field: "feed_limit",
      colId: "feed_limit",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["ST_SETTINGS"], logType)
    },
    {
      headerName: "Feeder 1",
      field: "feeder_1",
      colId: "feeder_1",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["ST_SETTINGS"], logType)
    },
    {
      headerName: "Feeder 2",
      field: "feeder_2",
      colId: "feeder_2",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["ST_SETTINGS"], logType)
    },
    {
      headerName: "Feeder 3",
      field: "feeder_3",
      colId: "feeder_3",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["ST_SETTINGS"], logType)
    },
    {
      headerName: "Status",
      field: "pmStatus",
      colId: "pmStatus",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["ST_SETTINGS"], logType)
    },
    {
      headerName: "Feeding Level",
      field: "feeder_level",
      colId: "feeder_level",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["ST_SETTINGS"], logType)
    },
    {
      headerName: "Low DO",
      field: "do_config",
      colId: "do_config",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["PG_SETTINGS"], logType)
    },
    {
      headerName: "DO Critical",
      field: "do_critical",
      colId: "do_critical",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["PG_SETTINGS"], logType)
    },
    {
      headerName: "Temp Range(From)",
      field: "temp_config",
      colId: "temp_config",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["PG_SETTINGS"], logType)
    },
    {
      headerName: "Temp Range(To)",
      field: "temp_config_to",
      colId: "temp_config_to",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["PG_SETTINGS"], logType)
    },
    {
      headerName: "Alias Name",
      field: "alias_name",
      colId: "alias_name",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["PG_SETTINGS"], logType)
    },
    {
      headerName: "Alias Name",
      field: "alias_name",
      colId: "alias_name",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["GT_SETTINGS"], logType)
    },
    {
      headerName: "Allow Other Location Devices",
      field: "allow_all_devices",
      colId: "allow_all_devices",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["GT_SETTINGS"], logType)
    },
    {
      headerName: "Pond Name",
      field: "pond_name",
      colId: "pond_name",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(
        [
          "POND_DEVICES",
          "MANUAL_POND_FEEDINGS",
          "PONDS_LAB_TEST",
          "PONDS_MEDICINES",
          "POND_SCHEDULE_SETS",
          "PONDS_HARVEST",
          "PONDS_AQUASIMS",
          "ABW",
          "POND_SETTINGS"
        ],
        logType
      )
    },
    {
      headerName: "Location",
      field: "location",
      colId: "location",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(
        ["GT_SETTINGS", "USER_LOCATIONS", "POND_DEVICES"],
        logType
      )
    },
    {
      headerName: "ABW",
      field: "abw",
      colId: "abw",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["ABW"], logType)
    },
    {
      headerName: "Survival Percentage",
      field: "survival_percentage",
      colId: "survival_percentage",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["ABW"], logType)
    },
    {
      headerName: "Pond Size",
      field: "size",
      colId: "size",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["POND_SETTINGS"], logType)
    },
    {
      headerName: "Units",
      field: "units",
      colId: "units",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["POND_SETTINGS"], logType)
    },
    {
      headerName: "Stocking Date",
      field: "cultivation_date",
      colId: "cultivation_date",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["POND_SETTINGS"], logType)
    },
    {
      headerName: "PL Stocked",
      field: "post_larva_stocked",
      colId: "post_larva_stocked",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["POND_SETTINGS"], logType)
    },
    {
      headerName: "Initial ABW",
      field: "initial_abw_id",
      colId: "initial_abw_id",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["POND_SETTINGS"], logType)
    },
    {
      headerName: "Status",
      field: "status",
      colId: "status",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["POND_SETTINGS"], logType)
    },
    {
      headerName: "Feed Name",
      field: "name",
      colId: "name",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["MANUAL_POND_FEEDINGS"], logType)
    },
    {
      headerName: "Dispensed Feed",
      field: "feed_dispensed",
      colId: "feed_dispensed",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["MANUAL_POND_FEEDINGS"], logType)
    },
    {
      headerName: "Feed Added Date",
      field: "feedDate",
      colId: "feedDate",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["MANUAL_POND_FEEDINGS"], logType)
    },
    {
      headerName: "HOC",
      field: "harvestDate",
      colId: "harvestDate",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["PONDS_HARVEST"], logType)
    },
    {
      headerName: "ABW",
      field: "abw",
      colId: "abw",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["PONDS_HARVEST"], logType)
    },
    {
      headerName: "Biomass",
      field: "biomass",
      colId: "biomass",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["PONDS_HARVEST"], logType)
    },
    {
      headerName: "Biomass Units",
      field: "biomass_units",
      colId: "biomass_units",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["PONDS_HARVEST"], logType)
    },
    {
      headerName: "Cumulative Feed",
      field: "cumulative_feed",
      colId: "cumulative_feed",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["PONDS_HARVEST"], logType)
    },
    {
      headerName: "Status",
      field: "type",
      colId: "type",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["PONDS_HARVEST"], logType)
    },
    {
      headerName: "FCR",
      field: "fcr",
      colId: "fcr",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["PONDS_HARVEST"], logType)
    },
    {
      headerName: "Variety",
      field: "type",
      colId: "type",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["PONDS_MEDICINES"], logType)
    },
    {
      headerName: "Resource",
      field: "resource",
      colId: "resource",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["PONDS_MEDICINES"], logType)
    },
    {
      headerName: "Quantity",
      field: "quantity",
      colId: "quantity",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["PONDS_MEDICINES"], logType)
    },
    {
      headerName: "Note",
      field: "notes",
      colId: "notes",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["PONDS_MEDICINES"], logType)
    },
    {
      headerName: "Lab Name",
      field: "lab_id",
      colId: "lab_id",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["PONDS_LAB_TEST", "USER_LABS"], logType)
    },
    {
      headerName: "pH",
      field: "ph",
      colId: "ph",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["PONDS_LAB_TEST"], logType)
    },
    {
      headerName: "Salinity(PPT)",
      field: "salinity",
      colId: "salinity",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["PONDS_LAB_TEST"], logType)
    },
    {
      headerName: "DO",
      field: "do",
      colId: "do",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["PONDS_LAB_TEST"], logType)
    },
    {
      headerName: "Temp",
      field: "temperature",
      colId: "temperature",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["PONDS_LAB_TEST"], logType)
    },
    {
      headerName: "CO3",
      field: "co3",
      colId: "co3",
      sortable: true,
      filter: true,
      required: false,
      visible: isIntersect(["PONDS_LAB_TEST"], logType)
    },
    {
      headerName: "HCO3",
      field: "hco3",
      colId: "hco3",
      sortable: true,
      filter: true,
      required: false,
      visible: isIntersect(["PONDS_LAB_TEST"], logType)
    },
    {
      headerName: "Hardness(PPM)",
      field: "hardness",
      colId: "hardness",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["PONDS_LAB_TEST"], logType)
    },
    {
      headerName: "Ammonia(PPM)",
      field: "ammonia",
      colId: "ammonia",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["PONDS_LAB_TEST"], logType)
    },
    {
      headerName: "Ammonium(PPM)",
      field: "ammonium",
      colId: "ammonium",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["PONDS_LAB_TEST"], logType)
    },
    {
      headerName: "TAN",
      field: "tan",
      colId: "tan",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["PONDS_LAB_TEST"], logType)
    },
    {
      headerName: "Iron(PPM)",
      field: "iron",
      colId: "iron",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["PONDS_LAB_TEST"], logType)
    },
    {
      headerName: "Green Colonies",
      field: "green_colonies",
      colId: "green_colonies",
      sortable: true,
      filter: true,
      required: false,
      visible: isIntersect(["PONDS_LAB_TEST"], logType)
    },
    {
      headerName: "Yellow Colonies",
      field: "yellow_colonies",
      colId: "yellow_colonies",
      sortable: true,
      filter: true,
      required: false,
      visible: isIntersect(["PONDS_LAB_TEST"], logType)
    },
    {
      headerName: "Set Name",
      field: "set_name",
      colId: "set_name",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["POND_SCHEDULE_SETS"], logType)
    },
    {
      headerName: "Feed Ratio %",
      field: "feed_percentage",
      colId: "feed_percentage",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["POND_SCHEDULE_SETS"], logType)
    },
    {
      headerName: "	Start Time",
      field: "s_time",
      colId: "s_time",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["POND_SCHEDULE_SETS"], logType)
    },
    {
      headerName: "	End Time",
      field: "e_time",
      colId: "e_time",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["POND_SCHEDULE_SETS"], logType)
    },
    {
      headerName: "Assigned Status",
      field: "pond_status",
      colId: "pond_status",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["POND_SCHEDULE_SETS"], logType)
    },
    {
      headerName: "Pond Assigned Feed Type",
      field: "pond_feed_type",
      colId: "pond_feed_type",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["POND_SCHEDULE_SETS"], logType)
    },
    {
      headerName: "Lab Phone",
      field: "phone",
      colId: "phone",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["USER_LABS"], logType)
    },
    {
      headerName: "Technician Name",
      field: "technician_name",
      colId: "technician_name",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["USER_LABS"], logType)
    },
    {
      headerName: "Technician Phone",
      field: "technician_phone",
      colId: "technician_phone",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["USER_LABS"], logType)
    },
    {
      headerName: "Lab Address",
      field: "address",
      colId: "address",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["USER_LABS"], logType)
    },
    {
      headerName: "Vendor Name",
      field: "vendor_name",
      colId: "vendor_name",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["RESOURCE_VENDORS"], logType)
    },
    {
      headerName: "Mobile Number",
      field: "vendor_mobile",
      colId: "vendor_mobile",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["RESOURCE_VENDORS"], logType)
    },
    {
      headerName: "Resource Name",
      field: "resource_name",
      colId: "resource_name",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["USER_RESOURCES"], logType)
    },
    {
      headerName: "Type",
      field: "resource_type",
      colId: "resource_type",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["USER_RESOURCES"], logType)
    },
    {
      headerName: "1Kg DIspence Time",
      field: "kg_dispensed_time",
      colId: "kg_dispensed_time",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["USER_RESOURCES"], logType)
    },
    {
      headerName: "Units",
      field: "units",
      colId: "units",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["USER_RESOURCES"], logType)
    },
    {
      headerName: "Feed Name",
      field: "feed_name",
      colId: "feed_name",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["STOCK_FEEDS"], logType)
    },
    {
      headerName: "No. of Bags",
      field: "number_of_units",
      colId: "number_of_units",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["STOCK_FEEDS"], logType)
    },
    {
      headerName: "Medicine Name",
      field: "resource_id",
      colId: "resource_id",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["STOCK_MEDICINES"], logType)
    },
    {
      headerName: "No. of Units",
      field: "numberOfUnits",
      colId: "numberOfUnits",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["STOCK_MEDICINES"], logType)
    },
    {
      headerName: "Bag Weight",
      field: "unit_quantity",
      colId: "unit_quantity",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["STOCK_FEEDS", "STOCK_MEDICINES"], logType)
    },
    {
      headerName: "Vendor Name",
      field: "vendor_id",
      colId: "vendor_id",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["STOCK_FEEDS", "STOCK_MEDICINES"], logType)
    },
    {
      headerName: "purchased Date",
      field: "purchaseDate",
      colId: "purchaseDate",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["STOCK_FEEDS", "STOCK_MEDICINES"], logType)
    },
    {
      headerName: "Last Updated Date",
      field: "last_updated_at",
      colId: "last_updated_at",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["STOCK_FEEDS", "STOCK_MEDICINES"], logType)
    },
    {
      headerName: "Aquasim",
      field: "aqua_sim_pond_id",
      colId: "aqua_sim_pond_id",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["PONDS_AQUASIMS"], logType)
    },
    {
      headerName: "Aqua Schedules ",
      field: "aqua_sim_auto_schedules",
      colId: "aqua_sim_auto_schedules",
      sortable: true,
      filter: true,
      required: true,
      visible: isIntersect(["PONDS_AQUASIMS"], logType)
    },
    ...gatewaySettingsConfig.map(keyConfig => {
      return {
        headerName: keyConfig.label,
        field: keyConfig.model,
        colId: keyConfig.model,
        sortable: false,
        filter: true,
        required: false,
        visible: isIntersect(["GT_SETTINGS"], logType)
      };
    }),
    ...pgSettingsConfig.map(keyConfig => {
      return {
        headerName: `PG ${keyConfig.label}`,
        field: keyConfig.model,
        colId: `${keyConfig.model}`,
        sortable: false,
        filter: true,
        required: false,
        visible: isIntersect(["PG_SETTINGS"], logType)
      };
    }),
    ...pmSettingsConfig.map(keyConfig => {
      return {
        headerName: `PM ${keyConfig.label}`,
        field: keyConfig.model,
        colId: `${keyConfig.model}`,
        sortable: false,
        filter: true,
        required: false,
        visible: isIntersect(["PM_SETTINGS"], logType)
      };
    }),
    ...stSettingsConfig.map(keyConfig => {
      return {
        headerName: `ST ${keyConfig.label}`,
        field: keyConfig.model,
        colId: `${keyConfig.model}`,
        sortable: false,
        filter: true,
        required: false,
        visible: isIntersect(["ST_SETTINGS"], logType)
      };
    })
  ].reduce((acc, column) => {
    if (acc[column.colId] !== undefined && acc[column.colId].required) {
      return acc;
    }
    acc[column.colId] = column;
    return acc;
  }, {});
  return Object.values(columns);
};
export default getTableColumnsConfig;
