<template>
  <div class="verify_dialog">
  <el-dialog
    width="25%"
    :visible="dialogVisible"
    :title="dailogTitle.title"
    :close-on-click-modal="false"
    @close="handleCloseDialog"
  >
    <el-form label-position="top" size="small" :model="user">
      <p v-if="!sendOTP" class="p-tag">
        <b>{{ $t(dailogTitle.oldData) }}:</b>{{ " " + dailogTitle.maskValue }}
      </p>
      <p v-else class="p-tag p-verify-tag">
        {{ $t("comn_otp_msg") + " " + dailogTitle.maskValue }}
      </p>
      <el-form-item
        v-if="!sendOTP && dailogTitle.name === 'Mobile'"
        :label="$t('Comn_mobile')"
        :error="aekmm__castErrorKeyToLang(ehm__errMessagesObject.mobile)"
      >
        <el-input
          v-model="user.mobile"
          :placeholder="$t('Usrs_enter_mobile_num')"
        ></el-input>
      </el-form-item>
      <el-form-item
        v-else-if="!sendOTP && dailogTitle.name === 'Email'"
        :label="$t('Comn_email')"
        :error="aekmm__castErrorKeyToLang(ehm__errMessagesObject.email)"
      >
        <el-input
          v-model="user.email"
          :placeholder="$t('Usrs_enter_email_addr')"
        ></el-input>
      </el-form-item>
      <el-form-item v-else :label="$t('comn_enter_OTP')">
        <el-input v-model="otp" :placeholder="$t('comn_enter_OTP')"></el-input>
      </el-form-item>
    </el-form>
    <template v-if="!sendOTP">
      <template v-if="getStoreUserProfileData.features.allowed_mobile_login">
        <p v-if="dailogTitle.name === 'Mobile'" class="p-tag otp-msg">
          {{ $t("user_mobile_otp_msg") }}
        </p>
      </template>
      <p v-if="dailogTitle.name === 'Email'" class="p-tag otp-msg">{{ $t("user_email_otp_msg") }}</p>
    </template>
    <div class="time-container" v-else>
      <p class="p-tag time-tag">Time: {{ formattedTimer }}</p>
      <el-button
        type="text"
        class="resend-otp"
        :disabled="timer !== 0"
        @click="handleResendOTP(dailogTitle.name)"
        >{{ $t("resend_OTP") }}</el-button
      >
    </div>
    <div class="footer-container" slot="footer">
      <template v-if="!sendOTP">
        <er-button
          v-if="
            dailogTitle.name === 'Mobile' &&
              !getStoreUserProfileData.features.allowed_mobile_login
          "
          size="mini"
          btnType="save"
          :showLabel="true"
          :showIcon="true"
          @click="submitUpdatedMobile"
          :loading="parentBtnLoading"
        ></er-button>
        <er-button
          v-else
          btnType="add"
          :showLabel="false"
          :showIcon="false"
          :loading="btnLoading"
          @click="handleSendOtp(dailogTitle.name)"
        >
          {{ $t("send_otp") }}
        </er-button>
      </template>
      <template v-else>
        <er-button
          btnType="cancel"
          :showLabel="false"
          :showIcon="false"
          @click="handleGoBack"
        >
          {{ $t("Back") }}
        </er-button>
        <er-button
          btnType="add"
          :showLabel="false"
          :showIcon="false"
          :loading="btnLoading"
          @click="handleVerifyOtp(dailogTitle.name)"
        >
          {{ $t("comn_verify") }}
        </er-button>
      </template>
    </div>
  </el-dialog>
</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import User from "@/model/user";
import errorHandlerMixin from "@/mixins/errorHandlerMixin.js";
import authErrorKeyMapMixin from "@/mixins/authErrorKeyMapMixin";
import errorKeyMapMixin from "@/mixins/errorKeyMapMixin";
import filtersMixin from "@/mixins/filtersMixin.js";

export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    dailogTitle: {
      type: Object,
      default: () => ({})
    },
    userData: {
      type: Object,
      default: () => ({})
    },
    includeUserid: {
      type: Boolean,
      default: false
    },
    ehm__errMessagesObjectCopy: {
      type: Object,
      default: () => ({})
    },
    parentBtnLoading: {
      type: Boolean,
      default: false
    },
  },
  mixins: [
    errorHandlerMixin,
    authErrorKeyMapMixin,
    errorKeyMapMixin,
    filtersMixin
  ],
  data: function() {
    return {
      timer: 30,
      sendOTP: false,
      btnLoading: false,
      user: new User(),
      ehm__errMessagesObject: new User(),
      otp: ''
    }
  },
  watch: {
    ehm__errMessagesObjectCopy(newVal, oldVal) {
      this.ehm__errMessagesObject = this.ehm__errMessagesObjectCopy;
    }
  },
  mounted() {
    this.user = this.$lodash.cloneDeep(this.userData)
  },
  computed: {
    ...mapGetters("user", {
      isReadOnly: "isReadOnly",
      // getProfileUrl: "getProfileUrl",
      getStoreUserProfileData: "getUserProfile"
    }),
    formattedTimer() {
      const minutes = Math.floor(this.timer / 60).toString().padStart(2, '0');
      const seconds = (this.timer % 60).toString().padStart(2, '0');
      return `${minutes}:${seconds}`;
    },
  },
  methods: {
    ...mapActions("user", {
      sendOTPFun: "sendOTP",
      verifyOTP: "verifyOTP"
    }),
    async handleResendOTP(data) {
      this.timer = 30;
      this.startTimer();
      try {
      const payload = this.includeUserid ? { user_type: this.user.user_type, user_id: this.user._id } : {};
      if (data === "Mobile") {
        await this.sendOTPFun({ mobile: this.user.mobile, ...payload });
      } else {
        await this.sendOTPFun({ email: this.user.email, ...payload })
      }
      this.$notify({
        title: this.$t("Usrs_success_msg"),
        message: this.$t("otp_sent_success_msg"),
        duration: 5000,
        type: "success"
      });
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }
    },
    startTimer() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
      this.intervalId = setInterval(() => {
        if (this.timer > 0) {
          this.timer--;
        } else {
          clearInterval(this.intervalId);
        }
      }, 1000);
    },
    async handleSendOtp(data) {
      try {
      this.btnLoading = true;
      this.timer = 30;
      this.startTimer();
      this.ehm__errMessagesObject = new User();
      const payload = this.includeUserid ? { user_type: this.user.user_type, user_id: this.user._id } : {};
      if (data === "Mobile") {
        await this.sendOTPFun({ mobile: this.user.mobile, ...payload });
      } else {
        await this.sendOTPFun({ email: this.user.email, ...payload })
      }
      this.$emit("handleSendOtp", data, { email: this.user.email, mobile: this.user.mobile })
      this.$notify({
        title: this.$t("Usrs_success_msg"),
        message: this.$t("otp_sent_success_msg"),
        duration: 5000,
        type: "success"
      });
      this.sendOTP = !this.sendOTP;
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.btnLoading = false;
      }
    },
    handleGoBack() {
      this.sendOTP = !this.sendOTP;
    },
    async handleVerifyOtp(data) {
      try {
        this.btnLoading = true;
        const payload = this.includeUserid ? { user_type: this.user.user_type, user_id: this.user._id } : {};
        if (data === "Mobile") {
        await this.verifyOTP({ mobile: this.user.mobile, otp: this.otp, ...payload });
      } else {
        await this.verifyOTP({ email: this.user.email, otp: this.otp, ...payload })
      }
      this.$notify({
        title: this.$t("Usrs_success_msg"),
        message: this.$t("otp_verify_success_msg"),
        duration: 5000,
        type: "success"
      });
      this.$emit("handleVerifyOtp", data)
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.btnLoading = false;
      }
    },
    handleCloseDialog() {
      this.user.mobile = this.dailogTitle.oldMobile;
      this.user.email = this.dailogTitle.oldEmail;
      this.sendOTP = false;
      this.otp = "";
      this.ehm__errMessagesObject = new User();
      this.$emit("handleCloseDialog", false)
    },
    submitUpdatedMobile() {
      this.$emit("submitUpdatedMobile", this.user)
    },
    ehm__error403Handler(err, isAuthenticated) {
      const errorData = err.response.data;
      if (errorData.error_code === "INVALID_OTP") {
        this.$notify({
          title: this.$t("failed"),
          message: this.$t("invalid_OTP"),
          type: "error"
        });
      }
      if (errorData.error_code === "OTP_EXPIRED") {
        this.$notify({
          title: this.$t("failed"),
          message: this.$t("OTP_expired"),
          type: "error"
        });
      }
    },
    ehm__error409Handler(err, isAuthenticated) {
      const errorData = err.response.data;
      if (errorData.error_code === "EMAIL_ALREADY_EXISTS") {
        this.$notify({
          title: this.$t("failed"),
          message: this.$t("Email_already_registered"),
          type: "error"
        });
      }
      if (errorData.error_code === "ACCOUNT_ALREADY_VERIFIED") {
        this.$notify({
          title: this.$t("failed"),
          message: this.dailogTitle.name === 'Mobile' ? this.$t("mobile_already_Verified") : this.$t("Email_already_Verified"),
          type: "error"
        });
      }
    },
  }
}
</script>
<style lang="scss">
.verify_dialog {
  .el-dialog {
    border-radius: 10px;
    .p-tag {
      opacity: 0.7;
      font-size: 11px;
      color: #000000;
    }
    .el-dialog__body {
      padding: 5px 20px 0px;
    }
    .el-dialog__header {
      padding: 16px 20px 10px;
    }
    .el-dialog__footer {
      padding: 16px 20px 10px;
      display: flex;
      justify-content: center;
    }
    .el-dialog__headerbtn {
      top: 18px;
      .el-dialog__close {
        color: #f54e5e;
        @include responsiveProperty(
          font-size,
          $app_font_size_2,
          $app_font_size_3,
          $app_font_size_xl
        );
      }
    }
    .el-form--label-top .el-form-item__label {
      padding: 0px;
      font-weight: 700;
      @include responsiveProperty(
        font-size,
        $app_font_size_1,
        $app_font_size_2,
        $app_font_size_3
      );
    }
    .el-form-item--small {
      margin-bottom: 0px;
    }
    .el-form-item__content {
      width: 100%;
    }
    .otp-msg {
      padding-top: 16px;
      text-align: center;
      word-wrap: break-word;
    }
    .time-tag {
      padding-top: 6px;
      text-align: left;
    }
    .resend-otp {
      padding-top: 6px;
      text-align: right;
      font-size: 12px;
    }
    .time-container {
      display: flex;
      justify-content: space-between;
    }
    .p-verify-tag {
      text-align: center;
      white-space: pre-wrap;
      word-wrap: break-word;
    }
  }
}
</style>
