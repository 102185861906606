/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: index.js
Description: This file is the route file of the router configuration. It imports all the router conguration and initializes the vue-router object
*/
/* eslint-disable */
import Vue from "vue";
import Router from "vue-router";
// import config from '@/config/app'
import dummy from "@/views/dummy";
import setupScreen from "@/views/setupScreen";
import view404 from "@/views/view404";
import viewNoInternet from "@/views/viewNoInternet";
import accountManagerRoutes from "./accountManagerRoutes";
import skrettingTechnicianRoutes from "./skrettingTechnicianRoutes";
import dealerRoutes from "./dealerRoutes";
import pondlogsRoutes from "./pondlogsRoutes";
import adminRoutes from "./adminRoutes";
import authRoutes from "./authRoutes";

Vue.use(Router);

const routes = [
  dealerRoutes,
  pondlogsRoutes,
  adminRoutes,
  authRoutes,
  accountManagerRoutes,
  skrettingTechnicianRoutes,
  {
    path: "*",
    redirect: "404"
  },
  {
    path: "/404",
    name: "page404",
    component: view404,
    meta: {
      langKey: "PondLogs"
    }
  },
  {
    path: "/nointernet",
    name: "nointernet",
    component: viewNoInternet,
    meta: {
      langKey: "PondLogs"
    }
  },
  {
    path: "/redirected-from-v1",
    component: setupScreen
  },
  {
    path: "/account-verify",
    redirect: "/link-verify"
  }
];

const router = new Router({ mode: "history", routes });
export default router;
