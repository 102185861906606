<template>
  <div class="release-popup-container">
    <div class="dialog-container">
      <er-dialog
        width="50%"
        v-if="dialogVisible"
        :visible.sync="dialogVisible"
        :append-to-body="true"
        @open="handleOpenDialog"
        :title="getDialogTitle"
        class="action-on-new-version"
        custom-class="New_Version"
        v-loading="loading"
        :before-close="handleClose"
      >
        <div class="release-content">
          <div class="feature-container">
            <h1>New Features</h1>
            <ol>
              <li v-for="(item, i) in dialogData.features" :key="i">
                {{ item }}
              </li>
            </ol>
          </div>
          <div class="enhancement-container">
            <h1>Enhancements</h1>
            <ol>
              <li v-for="(item, i) in dialogData.enhancements" :key="i">
                {{ item }}
              </li>
            </ol>
          </div>
        </div>

        <template slot="footer">
          <!-- <er-button
            btnType="confirm"
            :showLabel="true"
            :showIcon="true"
            :loading="saveLoading"
            @click="onConfirm"
            >confirm</er-button
          > -->
          <el-button type="primary" @click="onConfirm(dialogData._id)"
            >confirm</el-button
          >
          <!-- <er-button
            btnType="cancel"
            :showLabel="true"
            :loading="saveLoading"
            @click="handleCloseDialog"
          ></er-button> -->
        </template>
      </er-dialog>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  // props: {
  //   dialogVisible: {
  //     type: Boolean,
  //     default: () => false
  //   }
  // },
  data() {
    return {
      loading: false,
      dialogData: {
        // features: ["first feature", "second feature updated"],
        // enhancements: ["first enhancement", "second enhancement updated"],
        // status: "DRAFT",
        // _id: "625e9c2588508d36c0f37f1f",
        // version_number: "1.0.1",
        // created_at: "2022-04-19T11:25:25.844Z",
        // updated_at: "2022-04-19T11:26:38.325Z"
      },
      saveLoading: false,
      dialogVisible: false
    };
  },
  computed: {
    ...mapGetters("newRelease", {}),
    getDialogTitle() {
      return `PondLogs Update ${this.dialogData.version_number}`;
    }
  },
  async mounted() {
    const res = await this.callNewReleaseInfo();
    console.log("res", res?.data);
    if (res?.data.success && res.data.data.latest_ui_release) {
      this.setDialogData(res.data.data.latest_ui_release);
    }
  },
  methods: {
    ...mapActions("newRelease", {
      callNewReleaseInfo: "callNewReleaseInfo",
      setWatchStatus: "setWatchStatus"
    }),
    setDialogData(data) {
      // const {enhancements, features, version_number}  = data;
      this.dialogData = { ...data };
      this.dialogVisible = true;
    },
    handleOpenDialog() {
      //   to do
    },
    async onConfirm(id) {
      //   to do
      const payload = {
        notification_ref_id: id || "",
        notification_type: "UI_RELEASE"
      };
      const res = await this.setWatchStatus(payload);
      console.log("res", res);
      this.dialogVisible = false;
    },
    handleCloseDialog() {
      //   to do
    },
    handleClose() {
      // to do
      this.dialogVisible = false;
    }
  }
};
</script>

<style lang="scss">
h1 {
  color: #409eff;
  font-family: "Lato", "HelveticaNeue", "Arial", "sans-serif";
  // height: 20px;
  // background: #f2f0f9;
  // color: #0a2463;
  font-size: 16px;
  font-weight: 600;
}
ol {
  margin-left: 13px;
  margin-top: 10px;
  margin-bottom: 10px;

  li {
    font-size: 13px;
    margin-bottom: 6px;
  }
}
.el-dialog__footer {
  display: flex;
  justify-content: flex-end;
}
</style>
